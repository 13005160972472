import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../boot/api";
import { CommonOrganizationRole, DinahUserListOrganizationsOutput } from "../../../lib/grpcapi";

export const fetchUserOrganizations = createAsyncThunk(
  "user/organizations/fetchUserOrganizations",
  async () => {
    const { data } = await api.get(`/user/organizations`);
    return data;
  },
);

export interface OrganizationsState {
  organizations?: CommonOrganizationRole[];
}

const initialState: OrganizationsState = {};

const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setOrganizations: (state, action: PayloadAction<CommonOrganizationRole[]>) => {
      state.organizations = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserOrganizations.fulfilled, (state, action) => {
      if (action.payload) {
        const payload = action.payload as DinahUserListOrganizationsOutput;
        state.organizations = payload.OrganizationRoles;
      }
    });
  },
});

export const { setOrganizations } = organizationsSlice.actions;
export default organizationsSlice.reducer;
