import {
  CloseCircleOutlined,
  DeleteOutlined,
  DeploymentUnitOutlined,
  InfoCircleOutlined,
  SettingOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Alert, Button, Card, Dropdown, Empty, message, Popconfirm, Space, Tabs, TabsProps } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../boot/api";
import LoadingView from "../../../../components/utils/LoadingView";
import Padder from "../../../../components/utils/Padder";
import PageHeader from "../../../../components/utils/PageHeader";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { orgFetchOrgMirror } from "../../../../slices/app/org/mirrors";
import { setBreadcrumpItems } from "../../../../slices/breadcrump";
import styles from "./Show.module.css";

import OrgRenameMirrorDialog from "../../../../components/org/mirrors/OrgRenameMirrorDialog";
import OrgSetMirrorCountryCodesDialog from "../../../../components/org/mirrors/OrgSetMirrorCountryCodesDialog";
import OrgMirrorsShowConfiguration from "./show/Configuration";
import OrgMirrorsShowDomains from "./show/Domains";
import OrgMirrorsShowInformations from "./show/Informations";

const OrgMirrorsShow = () => {
  const dispatch = useAppDispatch();
  const mirror = useAppSelector(state => state.app.org.mirrors.mirror);
  const navigate = useNavigate();

  const params = useParams();

  const fetchMirrorTimeouts: React.MutableRefObject<any[]> = useRef([]);

  const [loading, setLoading] = useState(true);
  const [deployLoading, setDeployLoading] = useState(false);
  const [uninstallLoading, setUninstallLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [countryCodesDialogOpen, setCountryCodesDialogOpen] = useState(false);

  const clearFetchMirrorTimeouts = () => {
    fetchMirrorTimeouts.current.forEach(t => clearTimeout(t));
    fetchMirrorTimeouts.current = [];
  };

  useEffect(() => {
    return () => {
      clearFetchMirrorTimeouts();
    };
  }, []);

  useEffect(() => {
    const breadcrumpItems = [
      {
        label: "Mirrors",
        link: "/app/org/mirrors",
      },
    ];

    if (mirror?.ID === params.id) {
      breadcrumpItems.push({
        label: `${mirror?.Name} (${mirror?.Key})`,
        link: `/app/org/mirrors/${params.id}`,
      });
    }

    dispatch(setBreadcrumpItems(breadcrumpItems));
  }, [dispatch, mirror, params.id]);

  const fetchMirror = useCallback(async () => {
    await dispatch(orgFetchOrgMirror(params.id as string));
  }, [dispatch, params.id]);

  useEffect(() => {
    // Fetch mirror
    if (params.id && params.id.length) {
      (async () => {
        clearFetchMirrorTimeouts();

        setLoading(true);
        await fetchMirror();
        setLoading(false);

        const fetchMirrorLoop = async () => {
          clearFetchMirrorTimeouts();

          let tid = setTimeout(async () => {
            await fetchMirror();
            fetchMirrorLoop();
          }, 1000);

          fetchMirrorTimeouts.current.push(tid);
        };

        fetchMirrorLoop();
      })();
    }
  }, [fetchMirror, params.id]);

  const deploy = async () => {
    setDeployLoading(true);

    try {
      await api.post(`/org/mirrors/${mirror?.ID}/deploy`);
      message.success("Mirror will deploy");
    } catch (err) {
      console.error(err);
      message.error("Error on deploying");
    }

    // @TODO: small hack for no double click
    setTimeout(() => {
      setDeployLoading(false);
    }, 2000);
  };

  const uninstall = async () => {
    setUninstallLoading(true);

    try {
      await api.post(`/org/mirrors/${mirror?.ID}/uninstall`);
      message.success("Mirror will uninstall");
    } catch (err) {
      console.error(err);
      message.error("Error on uninstalling");
    }

    // @TODO: small hack for no double click
    setTimeout(() => {
      setUninstallLoading(false);
    }, 2000);
  };

  const deleteMirror = async () => {
    setDeleteLoading(true);

    try {
      await api.delete(`/org/mirrors/${mirror?.ID}`);
      setDeleteLoading(false);
      navigate("/app/org/mirrors");
      message.success("Mirror deleted");
    } catch (err) {
      console.error(err);
      message.error("Error on deleting");

      // @TODO: small hack for no double click
      setTimeout(() => {
        setDeleteLoading(false);
      }, 2000);
    }
  };

  if (loading) {
    return <LoadingView />;
  }

  if (!mirror) {
    return (
      <Padder>
        <Empty description="Error while loading organization" />
      </Padder>
    );
  }

  const tabItems: TabsProps["items"] = [
    {
      key: "informations",
      label: (
        <>
          <InfoCircleOutlined />
          &nbsp; Informations
        </>
      ),
      children: <OrgMirrorsShowInformations />,
    },
    {
      key: "config",
      label: (
        <>
          <SettingOutlined />
          &nbsp; Configuration
        </>
      ),
      children: <OrgMirrorsShowConfiguration />,
    },
    {
      key: "domains",
      label: (
        <>
          <UnorderedListOutlined />
          &nbsp; Domains
        </>
      ),
      children: <OrgMirrorsShowDomains />,
    },
  ];

  let deployDisabled = true;
  if (mirror?.Dirty) {
    deployDisabled = false;
  }
  if (mirror?.Status === "DEPLOYING" || mirror?.Status === "PENDING" || mirror?.Status === "UNINSTALLING") {
    deployDisabled = true;
  }

  let uninstallDisabled = false;
  if (mirror?.Status === "DEPLOYING" || mirror?.Status === "PENDING" || mirror?.Status === "UNINSTALLING") {
    uninstallDisabled = true;
  }

  let deleteDisabled = mirror?.Status !== "CREATED";

  return (
    <Padder>
      <Card>
        <PageHeader
          title={`${mirror ? mirror.Name : "--"}`}
          subTitle={`${mirror ? mirror.Key : "--"}`}
          actions={
            <Space>
              <Button
                type="primary"
                size="large"
                loading={deployLoading}
                disabled={deployDisabled || deployLoading}
                onClick={() => deploy()}
                icon={<DeploymentUnitOutlined />}
              >
                Deploy
              </Button>

              <Popconfirm
                placement="bottomLeft"
                title="Confirm uninstall ?"
                onConfirm={() => uninstall()}
              >
                <Button
                  size="large"
                  loading={deployLoading}
                  disabled={uninstallDisabled || uninstallLoading}
                  icon={<CloseCircleOutlined />}
                >
                  Uninstall
                </Button>
              </Popconfirm>
              <Popconfirm
                placement="bottomLeft"
                title="Confirm delete ?"
                onConfirm={() => deleteMirror()}
                disabled={deleteDisabled || deleteLoading}
              >
                <Button
                  danger
                  size="large"
                  loading={deleteLoading}
                  disabled={deleteDisabled || deleteLoading}
                  icon={<DeleteOutlined />}
                >
                  Delete
                </Button>
              </Popconfirm>

              <Dropdown
                menu={{
                  items: [
                    {
                      key: "rename",
                      label: "Rename mirror",
                      onClick: () => setRenameDialogOpen(true),
                    },
                    {
                      key: "country_codes",
                      label: "Change country codes",
                      onClick: () => setCountryCodesDialogOpen(true),
                    },
                  ],
                }}
                placement="bottomLeft"
                arrow
              >
                <Button size="large">
                  <SettingOutlined />
                </Button>
              </Dropdown>
            </Space>
          }
        />
      </Card>
      <div className={styles.content}>
        {mirror.Dirty && (
          <Alert
            message="Mirror configuration has pending changes. Click on the Deploy button to apply new configuration."
            type="warning"
            style={{ marginBottom: "1rem" }}
            showIcon
          />
        )}

        <Alert
          message={
            <div style={{ fontSize: "0.9rem" }}>
              First full deployment of the mirror <b>can take some time</b> (up to <b>10 minutes</b>{" "}
              after click on the Deploy button).
            </div>
          }
          type="warning"
          showIcon
        />

        <Card className={styles.mainCard}>
          <Tabs defaultActiveKey="informations" items={tabItems} />
        </Card>
      </div>

      <OrgRenameMirrorDialog
        isOpen={renameDialogOpen}
        mirror={mirror}
        onClose={() => setRenameDialogOpen(false)}
        onConfirm={() => setRenameDialogOpen(false)}
      />

      <OrgSetMirrorCountryCodesDialog
        isOpen={countryCodesDialogOpen}
        mirror={mirror}
        onClose={() => setCountryCodesDialogOpen(false)}
        onConfirm={() => setCountryCodesDialogOpen(false)}
      />
    </Padder>
  );
};

export default OrgMirrorsShow;
