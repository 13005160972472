import { Select } from "antd";
import { SelectProps } from "antd";
import { useEffect, useState } from "react";
import api from "../../../boot/api";
import { CommonBackendProvider, DinahOrgListBackendProvidersOutput } from "../../../lib/grpcapi";

interface Props extends SelectProps {}

const OrgBackendProviderSelect = ({ loading, onChange, value, ...otherProps }: Props) => {
  const [localLoading, setLocalLoading] = useState(false);
  const [backendProviders, setBackendProviders] = useState<CommonBackendProvider[]>([]);

  useEffect(() => {
    (async () => {
      setLocalLoading(true);
      const res = await api.get("/org/backend-providers");
      const data = res.data as DinahOrgListBackendProvidersOutput;
      setBackendProviders(data.BackendProviders || []);
      setLocalLoading(false);
    })();
  }, []);

  const options = backendProviders.map(backendProvider => ({
    label: backendProvider.Name,
    value: backendProvider.ID,
  }));

  let selectValue = undefined;
  if (value) {
    let vv = value as CommonBackendProvider;
    selectValue = {
      label: vv.Name,
      value: vv.ID,
    };
  }

  return (
    <Select
      {...otherProps}
      value={selectValue}
      loading={localLoading || loading}
      disabled={localLoading || loading}
      options={options}
      onChange={(value, options) => {
        // Retrieve provider using ID
        let provider: CommonBackendProvider | undefined;
        backendProviders.forEach((p) => {
          if (p.ID === value) {
            provider = p;
          }
        });

        onChange && onChange(provider, options);
      }}
    />
  );
};

export default OrgBackendProviderSelect;
