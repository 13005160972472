import { Button, Form, Input } from "antd";
import psl, { ParsedDomain } from "psl";
import { CommonDomainProvider } from "../../../lib/grpcapi-admin";
import DomainProvidersSelect from "../domain-providers/Select";
import styles from "./Form.module.css";

export type FormValues = {
  namesString?: string;
  names?: string[];
  provider?: CommonDomainProvider;
};

interface Props {
  loading?: boolean;
  handleSubmit: (values: FormValues) => void;
}

const { TextArea } = Input;

const DomainForm = ({ loading, handleSubmit }: Props) => {
  const onSubmit = (values: FormValues) => {
    const domains: string[] = [];
    const domainsSplitted = (values.namesString || "").split("\n");
    for (let i = 0; i < domainsSplitted.length; i++) {
      const domain = domainsSplitted[i].replaceAll(" ", "");
      if (domain === "") {
        continue;
      }
      domains.push(domain);
    }

    values.names = domains;

    handleSubmit(values);
  };

  return (
    <div className={styles.container}>
      <Form
        disabled={loading}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        autoComplete="off"
        onFinish={onSubmit}
      >
        <Form.Item<FormValues>
          label="Domain provider"
          name="provider"
          rules={[{ required: true, message: "Backend provider required" }]}
        >
          <DomainProvidersSelect />
        </Form.Item>

        <Form.Item<FormValues>
          label="Domain names"
          name="namesString"
          rules={[
            { required: true, message: "Domain names required" },
            {
              validator(_, value) {
                const domains = (value || "").split("\n");

                for (let i = 0; i < domains.length; i++) {
                  const domain = domains[i].replaceAll(" ", "");
                  if (domain === "") {
                    continue;
                  }

                  let parsedDomain = psl.parse(domain);
                  if (!parsedDomain || (parsedDomain && parsedDomain.error)) {
                    return Promise.reject(`Invalid domain: ${domain}`);
                  }

                  parsedDomain = parsedDomain as ParsedDomain;

                  if (!parsedDomain.listed) {
                    return Promise.reject(`Invalid domain tld: ${domain}`);
                  }

                  if (parsedDomain.subdomain && parsedDomain.subdomain.length) {
                    return Promise.reject(`Dinah only support 2nd level domain: ${domain}`);
                  }
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <TextArea autoSize style={{ minHeight: "200px" }} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button loading={loading} type="primary" htmlType="submit">
            Create domain
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DomainForm;
