import { combineReducers, configureStore } from "@reduxjs/toolkit";
import reducers from "./slices/reducers";

const rootReducer = combineReducers(reducers);
const store = configureStore({ reducer: rootReducer });

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;
