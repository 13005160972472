import { Button, Card, Space } from "antd";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import DomainProvidersList from "../../../../components/admin/domain-providers/List";
import Padder from "../../../../components/utils/Padder";
import PageHeader from "../../../../components/utils/PageHeader";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { fetchDomainProviders } from "../../../../slices/app/admin/domain-providers";
import { setBreadcrumpItems } from "../../../../slices/breadcrump";
import styles from "./Index.module.css";

const AppDomainsIndex = () => {
  const dispatch = useAppDispatch();
  const domainProviders = useAppSelector(state => state.app.admin.domainProviders.domainProviders);
  useEffect(() => {
    dispatch(setBreadcrumpItems([
      {
        label: "Admin",
        link: "/app/admin",
      },
      {
        label: "Domain providers",
        link: "/app/admin/domain-providers",
      },
    ]));

    dispatch(fetchDomainProviders());
  }, [dispatch]);

  return (
    <Padder>
      <Card>
        <PageHeader
          title="Domain providers"
          subTitle="Domain providers management"
          actions={
            <Space>
              <Link to="/app/admin/domain-providers/new">
                <Button type="primary">
                  Create domain provider
                </Button>
              </Link>
            </Space>
          }
        />
      </Card>
      <div className={styles.content}>
        <DomainProvidersList domainProviders={domainProviders} />
      </div>
    </Padder>
  );
};

export default AppDomainsIndex;
