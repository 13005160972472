import { Select } from "antd";
import { useEffect, useState } from "react";
import adminApi from "../../../boot/admin-api";
import { AdminListDomainProvidersOutput, CommonDomainProvider } from "../../../lib/grpcapi-admin";
import styles from "./Select.module.css";

interface Props {
  disabled?: boolean;
  onChange?: (provider: CommonDomainProvider | undefined) => void;
}

const DomainProvidersSelect = ({ onChange, disabled }: Props) => {
  const [items, setItems] = useState<CommonDomainProvider[]>([]);

  const fetchProviders = async () => {
    try {
      const res = await adminApi.get("/domain-providers");
      const data = res.data as AdminListDomainProvidersOutput;
      setItems(data.DomainProviders || []);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // Fetch domain providers
    fetchProviders();
  }, []);

  const handleChange = (val: any) => {
    let provider: CommonDomainProvider | undefined;

    items.forEach(i => {
      if (i.ID === val) {
        provider = i;
      }
    });

    onChange && onChange(provider);
  };

  const options = items.map(provider => {
    return {
      label: provider.Name,
      value: provider.ID,
    };
  });

  return (
    <Select<CommonDomainProvider>
      disabled={disabled}
      className={styles.select}
      options={options}
      onChange={handleChange}
    />
  );
};

export default DomainProvidersSelect;
