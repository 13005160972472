import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import adminApi from "../../../boot/admin-api";
import {
  AdminGetDomainProviderOutput,
  AdminListDomainProvidersOutput,
  CommonDomainProvider,
} from "../../../lib/grpcapi-admin";

export const fetchDomainProviders = createAsyncThunk(
  "admin/domainProviders/fetchDomainProviders",
  async () => {
    const { data } = await adminApi.get(`/domain-providers`, {
      params: {
        Page: "1",
        Per: "20",
      },
    });

    return data;
  },
);

export const fetchDomainProvider = createAsyncThunk(
  "admin/domainProviders/fetchDomainProvider",
  async (id: string) => {
    const { data } = await adminApi.get(`/domain-providers/${id}`);
    return data;
  },
);

export interface DomainProvidersState {
  domainProviders?: CommonDomainProvider[];
  domainProvider?: CommonDomainProvider;
}

const initialState: DomainProvidersState = {};

const domainProvidersSlice = createSlice({
  name: "domainProviders",
  initialState,
  reducers: {
    setDomainProviders: (state, action: PayloadAction<CommonDomainProvider[]>) => {
      state.domainProviders = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDomainProviders.fulfilled, (state, action) => {
      if (action.payload) {
        const payload = action.payload as AdminListDomainProvidersOutput;
        state.domainProviders = payload.DomainProviders;
      }
    });
    builder.addCase(fetchDomainProvider.fulfilled, (state, action) => {
      if (action.payload) {
        const payload = action.payload as AdminGetDomainProviderOutput;
        state.domainProvider = payload.DomainProvider;
      }
    });
  },
});

export const { setDomainProviders } = domainProvidersSlice.actions;
export default domainProvidersSlice.reducer;
