import { EyeOutlined } from "@ant-design/icons";
import { Button, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import { CommonCDNProvider } from "../../../lib/grpcapi-admin";
import DateRender from "../../utils/DateRender";

interface Props {
  cdnProviders?: CommonCDNProvider[];
}

const AdminCDNProvidersList = ({ cdnProviders }: Props) => {
  if (!cdnProviders) {
    return null;
  }

  const columns: ColumnsType<CommonCDNProvider> = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
    },
    {
      title: "Creation date",
      key: "CreatedAt",
      render: (provider: CommonCDNProvider) => {
        return <DateRender date={provider.CreatedAt} />;
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: 200,
      render: (backendProvider) => (
        <Space size="middle">
          <Link to={`/app/admin/backend-providers/${backendProvider.ID}`}>
            <Button disabled type="link" icon={<EyeOutlined />}>Show</Button>
          </Link>
        </Space>
      ),
    },
  ];

  return <Table rowKey="ID" bordered columns={columns} dataSource={cdnProviders} />;
};

export default AdminCDNProvidersList;
