import { EyeOutlined } from "@ant-design/icons";
import { Button, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { TableProps } from "antd/es/table";
import { Link } from "react-router-dom";
import { CommonOrganization } from "../../../lib/grpcapi-admin";
import DateRender from "../../utils/DateRender";

interface Props extends TableProps<CommonOrganization> {
  organizations?: CommonOrganization[];
}

const DomainProvidersList = ({ organizations, ...otherProps }: Props) => {
  if (!organizations) {
    return null;
  }

  const columns: ColumnsType<CommonOrganization> = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "ID",
    },
    {
      title: "Creation date",
      key: "CreatedAt",
      render: (org: CommonOrganization) => {
        return <DateRender date={org.CreatedAt} />;
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: 200,
      render: (org) => (
        <Space size="middle">
          <Link to={`/app/admin/organizations/${org.ID}`}>
            <Button type="link" icon={<EyeOutlined />}>Show</Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <Table
      bordered
      rowKey="ID"
      columns={columns}
      dataSource={organizations}
      {...otherProps}
    />
  );
};

export default DomainProvidersList;
