import { Spin } from "antd";
import styles from "./LoadingView.module.css";

const LoadingView = () => {
  return (
    <div className={styles.container}>
      <Spin />
    </div>
  );
};

export default LoadingView;
