import { Button, Card, Space } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import adminApi from "../../../../boot/admin-api";
import DomainProvidersForm from "../../../../components/admin/domain-providers/Form";
import Padder from "../../../../components/utils/Padder";
import PageHeader from "../../../../components/utils/PageHeader";
import { AdminCreateDomainProviderInput } from "../../../../lib/grpcapi-admin";
import styles from "./New.module.css";

const AppDomainsNew = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSubmit = async (values: { [k: string]: any }) => {
    setLoading(true);

    // Prepare payload
    const { name, vendor, ...config } = values;
    const payload: AdminCreateDomainProviderInput = {
      Name: name,
      Vendor: vendor,
      Config: btoa(JSON.stringify(config)),
    };

    // Create domain provider
    try {
      await adminApi.post("/domain-providers", payload);
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  };

  return (
    <Padder>
      <Card>
        <PageHeader
          title="New domain providers"
          subTitle="Create a new domain providers"
          actions={
            <Space>
              <Button onClick={() => navigate(-1)}>
                Go Back
              </Button>
            </Space>
          }
        />
      </Card>

      <div className={styles.content}>
        <div className="row">
          <div className="col-lg-8 col-md-12 col-xs-12">
            <Card>
              <DomainProvidersForm
                loading={loading}
                handleSubmit={handleSubmit}
              />
            </Card>
          </div>
        </div>
      </div>
    </Padder>
  );
};

export default AppDomainsNew;
