import { Table } from "antd";
import { TableProps } from "antd/es/table";
import { ColumnsType } from "antd/es/table";
import { CommonDomain } from "../../../lib/grpcapi";
import DateRender from "../../utils/DateRender";

interface Props extends TableProps<CommonDomain> {
  domains?: CommonDomain[];
}

const OrgDomainsList = ({ domains, ...otherProps }: Props) => {
  if (!domains) {
    return null;
  }

  const columns: ColumnsType<CommonDomain> = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Mirror",
      key: "Mirror",
      render: (domain: CommonDomain) => {
        return domain.DomainDeployment?.Mirror?.Name;
      },
    },
    {
      title: "Provider",
      key: "Provider",
      render: (domain) => {
        return domain.DomainProvider?.Name;
      },
    },
    {
      title: "Creation date",
      key: "CreatedAt",
      render: (domain: CommonDomain) => {
        return <DateRender date={domain.CreatedAt} />;
      },
    },
  ];

  return (
    <Table
      bordered
      rowKey="ID"
      columns={columns}
      dataSource={domains}
      {...otherProps}
    />
  );
};

export default OrgDomainsList;
