import Editor, { Monaco } from "@monaco-editor/react";
import Form from "@rjsf/antd";
import { RJSFSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { Button, message, Radio } from "antd";
import { useEffect, useRef, useState } from "react";
import api from "../../../../../boot/api";
import { useAppSelector } from "../../../../../hooks";
import alicexConfigJSONSchema from "../../../../../lib/alicex-config-json-schema.json";

const OrgMirrorsShowConfiguration = () => {
  const mirror = useAppSelector(state => state.app.org.mirrors.mirror);

  const configSet = useRef(false);

  const [loading, setLoading] = useState(false);
  const [editorType, setEditorType] = useState("visual"); // visual / code

  const [codeConfig, setCodeConfig] = useState("");
  const [visualConfig, setVisualConfig] = useState({});

  useEffect(() => {
    if (mirror && mirror.AppConfig && mirror.AppConfig.length) {
      if (configSet.current) {
        return;
      }

      try {
        const configString = atob(mirror.AppConfig);
        const configParsed = JSON.parse(configString);

        setVisualConfig(configParsed);

        const configStringPretty = JSON.stringify(configParsed, null, 2);
        setCodeConfig(configStringPretty);

        configSet.current = true;
      } catch (err) {
        console.error(err);
      }
    }
  }, [mirror]);

  const saveAppConfig = async () => {
    setLoading(true);
    const configB64 = btoa(codeConfig);
    const payload = { AppConfig: configB64 };

    try {
      await api.post(`/org/mirrors/${mirror?.ID}/app-config`, payload);
      message.success("Configuration saved");
    } catch (err) {
      console.error(err);
      message.error("Configuration save error");
    }

    setLoading(false);
  };

  // Visual editor
  const visualEditorSchema = JSON.parse(JSON.stringify(alicexConfigJSONSchema)) as RJSFSchema;
  const visualEditor = (
    <Form
      schema={visualEditorSchema}
      disabled={loading}
      validator={validator}
      onChange={(data) => {
        setVisualConfig(data.formData);

        try {
          const strsd = JSON.stringify(data.formData, null, 2);
          setCodeConfig(strsd);
        } catch (err) {}
      }}
      formData={visualConfig}
    >
      {" "}
    </Form>
  );

  // Code editor
  const handleEditorDidMount = (_: any, monaco: Monaco) => {
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [{
        uri: "",
        fileMatch: ["*"],
        schema: alicexConfigJSONSchema,
      }],
    });
  };

  const monacoOptions = {
    selectOnLineNumbers: true,
    minimap: {
      enabled: false,
    },
  };

  const codeEditor = (
    <Editor
      options={monacoOptions}
      theme="vs-dark"
      width="100%"
      height="600px"
      defaultLanguage="json"
      defaultValue="{}"
      value={codeConfig}
      onMount={handleEditorDidMount}
      onChange={(cfg) => {
        setCodeConfig(cfg || "");

        try {
          const parsd = JSON.parse(cfg || "");
          setVisualConfig(parsd);
        } catch (err) {}
      }}
    />
  );

  return (
    <div>
      <Radio.Group value={editorType} onChange={(e) => setEditorType(e.target.value)}>
        <Radio.Button value="visual">Visual editor</Radio.Button>
        <Radio.Button value="code">JSON editor</Radio.Button>
      </Radio.Group>

      <br />
      <br />

      {editorType === "code" ? codeEditor : visualEditor}

      <br />

      <Button
        disabled={loading}
        size="large"
        type="primary"
        onClick={() => saveAppConfig()}
      >
        Save configuration
      </Button>
    </div>
  );
};

export default OrgMirrorsShowConfiguration;
