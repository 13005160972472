import { Button, Card, message, Space } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../../boot/api";
import OrgMirrorForm, { FormValues } from "../../../../components/org/mirrors/Form";
import Padder from "../../../../components/utils/Padder";
import PageHeader from "../../../../components/utils/PageHeader";
import { useAppDispatch } from "../../../../hooks";
import { CommonMirror, DinahOrgCreateMirrorInput, DinahOrgCreateMirrorOutput } from "../../../../lib/grpcapi";
import { setBreadcrumpItems } from "../../../../slices/breadcrump";
import styles from "./New.module.css";

const OrgMirrorNew = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(setBreadcrumpItems([
      {
        label: "Mirrors",
        link: "/app/org/mirrors",
      },
      {
        label: "New",
        link: "/app/org/mirrors/new",
      },
    ]));
  }, [dispatch]);

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    const payload: DinahOrgCreateMirrorInput = {
      BackendProviderID: values.backendProvider?.ID,
      CDNProviderID: values.cdnProvider?.ID,
      Name: values.name,
      Private: values.private,
      CountryCodes: values.country_codes?.map(c => c.abbreviation),
    };

    let success = false;
    let mirror: CommonMirror | undefined;
    try {
      const res = await api.post("/org/mirrors", payload);
      const data = res.data as DinahOrgCreateMirrorOutput;
      mirror = data.Mirror;

      message.success("Mirror created");
      success = true;
    } catch (err) {
      console.error(err);
      message.error("Error");
    }

    setLoading(false);

    if (success) {
      navigate(`/app/org/mirrors/${mirror?.ID}`);
    }
  };

  return (
    <Padder>
      <Card>
        <PageHeader
          title="New Mirror"
          subTitle="Create a new mirror"
          actions={
            <Space>
              <Button onClick={() => navigate(-1)}>
                Go Back
              </Button>
            </Space>
          }
        />
      </Card>

      <div className={styles.content}>
        <div className="row">
          <div className="col-lg-8 col-md-12 col-xs-12">
            <Card>
              <OrgMirrorForm loading={loading} handleSubmit={handleSubmit} />
            </Card>
          </div>
        </div>
      </div>
    </Padder>
  );
};

export default OrgMirrorNew;
