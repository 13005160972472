import { BrowserRouter, Route, Routes } from "react-router-dom";

import AppLayout from "../components/layouts/AppLayout";

import NotFound from "../containers/NotFound";
import Root from "../containers/Root";

// App
import AppRoot from "../containers/app/AppRoot";

// Admin =========================================================

import AdminRoot from "../containers/app/admin/AdminRoot";

// Domain providers
import AdminAppDomainProvidersIndex from "../containers/app/admin/domain-providers/Index";
import AdminAppDomainProvidersNew from "../containers/app/admin/domain-providers/New";
import AdminAppDomainProvidersShow from "../containers/app/admin/domain-providers/Show";

// Domains
import AdminAppDomainsIndex from "../containers/app/admin/domains/Index";
import AdminAppDomainsNew from "../containers/app/admin/domains/New";
import AdminAppDomainsShow from "../containers/app/admin/domains/Show";

// Organizations
import AdminAppOrganizationsIndex from "../containers/app/admin/organizations/Index";
import AdminAppOrganizationsNew from "../containers/app/admin/organizations/New";
import AdminAppOrganizationsShow from "../containers/app/admin/organizations/Show";

// Backend providers
import AdminAppBackendProvidersIndex from "../containers/app/admin/backend-providers/Index";

// CDN providers
import AdminAppCDNProvidersIndex from "../containers/app/admin/cdn-providers/Index";

// Org =========================================================

import OrgRoot from "../containers/app/org/OrgRoot";

// Mirrors
import OrgAppMirrorsIndex from "../containers/app/org/mirrors/Index";
import OrgAppMirrorsNew from "../containers/app/org/mirrors/New";
import OrgAppMirrorsShow from "../containers/app/org/mirrors/Show";

// Domains
import OrgAppDomainsIndex from "../containers/app/org/domains/Index";

const routes = (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Root />} />

      {/* App ================================================================================== */}
      <Route element={<AppLayout />}>
        <Route path="/app" element={<AppRoot />} />

        {/* Admin ============================================ */}

        <Route path="/app/admin" element={<AdminRoot />} />

        {/* Domains */}
        <Route path="/app/admin/domains" element={<AdminAppDomainsIndex />} />
        <Route path="/app/admin/domains/new" element={<AdminAppDomainsNew />} />
        <Route path="/app/admin/domains/:id" element={<AdminAppDomainsShow />} />

        {/* Domain providers */}
        <Route path="/app/admin/domain-providers" element={<AdminAppDomainProvidersIndex />} />
        <Route path="/app/admin/domain-providers/new" element={<AdminAppDomainProvidersNew />} />
        <Route path="/app/admin/domain-providers/:id" element={<AdminAppDomainProvidersShow />} />

        {/* Organizations */}
        <Route path="/app/admin/organizations" element={<AdminAppOrganizationsIndex />} />
        <Route path="/app/admin/organizations/new" element={<AdminAppOrganizationsNew />} />
        <Route path="/app/admin/organizations/:id" element={<AdminAppOrganizationsShow />} />

        {/* Backend providers */}
        <Route path="/app/admin/backend-providers" element={<AdminAppBackendProvidersIndex />} />

        {/* CDN providers */}
        <Route path="/app/admin/cdn-providers" element={<AdminAppCDNProvidersIndex />} />

        {/* Org ============================================== */}

        <Route path="/app/org" element={<OrgRoot />} />

        {/* Mirrors */}
        <Route path="/app/org/mirrors" element={<OrgAppMirrorsIndex />} />
        <Route path="/app/org/mirrors/new" element={<OrgAppMirrorsNew />} />
        <Route path="/app/org/mirrors/:id" element={<OrgAppMirrorsShow />} />

        {/* Domains */}
        <Route path="/app/org/domains" element={<OrgAppDomainsIndex />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);

export default routes;
