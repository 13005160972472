import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import adminApi from "../../../boot/admin-api";
import {
  AdminGetOrganizationOutput,
  AdminListOrganizationsOutput,
  AdminListOrganizationUsersOutput,
  CommonOrganization,
  CommonOrganizationRole,
} from "../../../lib/grpcapi-admin";

export const adminFetchOrganizations = createAsyncThunk(
  "admin/organizations/adminFetchOrganizations",
  async (params?: object) => {
    const { data } = await adminApi.get(`/organizations`, { params });

    return data;
  },
);

export const adminFetchOrganizationUsers = createAsyncThunk(
  "admin/organizations/adminFetchOrganizationUsers",
  async (organizationID: string) => {
    const { data } = await adminApi.get(`/organizations/${organizationID}/users`, {
      params: {
        Page: "1",
        Per: "20",
      },
    });

    return data;
  },
);

export const adminFetchOrganization = createAsyncThunk(
  "admin/organizations/adminFetchOrganization",
  async (id: string) => {
    const { data } = await adminApi.get(`/organizations/${id}`);
    return data;
  },
);

export interface OrganizationsState {
  organizations?: CommonOrganization[];
  organizationsTotalCount?: number;
  organization?: CommonOrganization;
  organzationUsers?: CommonOrganizationRole[];
}

const initialState: OrganizationsState = {};

const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setOrganizations: (state, action: PayloadAction<CommonOrganization[]>) => {
      state.organizations = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminFetchOrganizations.fulfilled, (state, action) => {
      if (action.payload) {
        const payload = action.payload as AdminListOrganizationsOutput;
        state.organizations = payload.Organizations;
        state.organizationsTotalCount = parseInt(payload.TotalCount || "") || 0;
      }
    });
    builder.addCase(adminFetchOrganization.fulfilled, (state, action) => {
      if (action.payload) {
        const payload = action.payload as AdminGetOrganizationOutput;
        state.organization = payload.Organization;
      }
    });
    builder.addCase(adminFetchOrganizationUsers.fulfilled, (state, action) => {
      if (action.payload) {
        const payload = action.payload as AdminListOrganizationUsersOutput;
        state.organzationUsers = payload.OrganizationRoles;
      }
    });
  },
});

export const { setOrganizations } = organizationsSlice.actions;
export default organizationsSlice.reducer;
