import { Spin } from "antd";
import styles from "./LoadingScreen.module.css";

const LoadingScreen = () => {
  return (
    <div className={styles.container}>
      <Spin size="large" />
    </div>
  );
};

export default LoadingScreen;
