import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  url: "https://auth.7wv6mz58y3pp.online",
  realm: "master",
  clientId: "dinah-web",
});

let initPrms: null | Promise<boolean> = null;

export const init = () => {
  if (initPrms === null) {
    initPrms = keycloak.init({ onLoad: "login-required" });
  }

  return initPrms;
  // return keycloak.init({ onLoad: "login-required" });
};

export default keycloak;
