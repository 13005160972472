import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../hooks";

const AppBreadcrump = () => {
  const items = useAppSelector(state => state.breadcrump.items);

  const breadcrumbItems = (items || []).map(item => {
    return {
      title: (
        <Link to={item.link}>
          {item.label}
        </Link>
      ),
    };
  });

  return <Breadcrumb items={breadcrumbItems} />;
};

export default AppBreadcrump;
