import { PlusOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OrganizationsList from "../../../../components/admin/organizations/List";
import Padder from "../../../../components/utils/Padder";
import PageHeader from "../../../../components/utils/PageHeader";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { adminFetchOrganizations } from "../../../../slices/app/admin/organizations";
import { setBreadcrumpItems } from "../../../../slices/breadcrump";
import styles from "./Index.module.css";

const AdminOrganizationsIndex = () => {
  const dispatch = useAppDispatch();
  const organizations = useAppSelector(state => state.app.admin.organizations.organizations);
  const organizationsTotalCount = useAppSelector(state => state.app.admin.organizations.organizationsTotalCount);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const fetchOrganizations = useCallback(async () => {
    setLoading(true);
    await dispatch(adminFetchOrganizations({ Per: pageSize, Page: page }));
    setLoading(false);
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    dispatch(setBreadcrumpItems([
      {
        label: "Admin",
        link: "/app/admin",
      },
      {
        label: "Organizations",
        link: "/app/admin/organizations",
      },
    ]));

    fetchOrganizations();
  }, [dispatch, fetchOrganizations]);

  return (
    <Padder>
      <Card>
        <PageHeader
          title="Organizations"
          subTitle="Organizations management"
          actions={
            <>
              <Link to="/app/admin/organizations/new">
                <Button type="primary" size="large" icon={<PlusOutlined />}>
                  Create organization
                </Button>
              </Link>
            </>
          }
        />
      </Card>
      <div className={styles.content}>
        <OrganizationsList
          pagination={{
            total: organizationsTotalCount,
            defaultCurrent: 1,
            current: page,
            showSizeChanger: true,
          }}
          organizations={organizations}
          loading={loading}
          onChange={(ev) => {
            setPageSize(ev.pageSize || 10);
            setPage(ev.current || 1);
          }}
        />
      </div>
    </Padder>
  );
};

export default AdminOrganizationsIndex;
