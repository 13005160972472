import { EyeOutlined } from "@ant-design/icons";
import { Button, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import { CommonDomainProvider } from "../../../lib/grpcapi-admin";
import DateRender from "../../utils/DateRender";

interface Props {
  domainProviders?: CommonDomainProvider[];
}

const DomainProvidersList = ({ domainProviders }: Props) => {
  if (!domainProviders) {
    return null;
  }

  const columns: ColumnsType<CommonDomainProvider> = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "ID",
    },
    {
      title: "Creation date",
      key: "CreatedAt",
      render: (provider: CommonDomainProvider) => {
        return <DateRender date={provider.CreatedAt} />;
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: 200,
      render: (domainProvider) => (
        <Space size="middle">
          <Link to={`/app/admin/domain-providers/${domainProvider.ID}`}>
            <Button type="link" icon={<EyeOutlined />}>Show</Button>
          </Link>
        </Space>
      ),
    },
  ];

  return <Table rowKey="ID" bordered columns={columns} dataSource={domainProviders} />;
};

export default DomainProvidersList;
