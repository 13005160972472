import { Button, Result } from "antd";
import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

class ErrorBoundary extends React.Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    console.error(error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    console.error(error, info.componentStack);
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return (
      <Result
        status="500"
        title="Something bad happened."
        subTitle="Our team has been informed and is working to resolve the problem."
        extra={[
          <Button type="primary" key="home" onClick={() => window.location.href = "/"}>
            Back to home
          </Button>,
        ]}
      />
    );
  }
}

export default ErrorBoundary;
