import { Button, Card, Space } from "antd";
import { message } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import adminApi from "../../../../boot/admin-api";
import OrganizationForm from "../../../../components/admin/organizations/Form";
import Padder from "../../../../components/utils/Padder";
import PageHeader from "../../../../components/utils/PageHeader";
import { AdminCreateOrganizationInput } from "../../../../lib/grpcapi-admin";
import styles from "./New.module.css";

const AdminAppOrganizationNew = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSubmit = async (values: { [k: string]: any }) => {
    setLoading(true);

    // Prepare payload
    const payload: AdminCreateOrganizationInput = { Name: values.name };

    // Create organizations
    let success = true;
    try {
      await adminApi.post("/organizations", payload);
    } catch (err) {
      success = false;
      console.error(err);
    }

    setLoading(false);

    if (success) {
      navigate("/app/admin/organizations");
      message.success("Organization created");
      return;
    }
  };

  return (
    <Padder>
      <Card>
        <PageHeader
          title="New organization"
          subTitle="Create an organization"
          actions={
            <Space>
              <Button size="large" onClick={() => navigate(-1)}>
                Go Back
              </Button>
            </Space>
          }
        />
      </Card>
      <div className={styles.content}>
        <div className="row">
          <div className="col-lg-8 col-md-12 col-xs-12">
            <Card>
              <OrganizationForm
                loading={loading}
                handleSubmit={handleSubmit}
              />
            </Card>
          </div>
        </div>
      </div>
    </Padder>
  );
};

export default AdminAppOrganizationNew;
