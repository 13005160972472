import { useEffect, useState } from "react";
import api from "../../boot/api";
import storage from "../../boot/storage";
import { useAppDispatch } from "../../hooks";
import { CommonOrganizationRole, DinahUserListOrganizationsOutput } from "../../lib/grpcapi";
import { setOrganizations } from "../../slices/app/user/organizations";
import { setSelectedOrganizationRole } from "../../slices/auth";
import LoadingScreen from "../utils/LoadingScreen";

interface Props {
  children: JSX.Element;
}

const OrganizationLoader = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      // Fetch organizations
      const res = await api.get(`/user/organizations`);
      const data = res.data as DinahUserListOrganizationsOutput;
      const organizationRoles = data.OrganizationRoles || [];

      dispatch(setOrganizations(organizationRoles));

      // Do nothing if user doestnt have any org
      if (!organizationRoles.length) {
        setLoading(false);
        return;
      }

      // Try to get existing selected org in storage
      const storageRoleString = await storage.getItem("selectedOrganizationRole") as string;
      if (storageRoleString) {
        let storageRole: CommonOrganizationRole | undefined;

        try {
          storageRole = JSON.parse(storageRoleString) as CommonOrganizationRole;
        } catch (err) {
          await storage.removeItem("selectedOrganizationRole");
        }

        if (storageRole) {
          // Check storage role exist in roles
          let valid = false;
          organizationRoles.forEach(r => {
            if (r.ID === storageRole?.ID) {
              valid = true;
            }
          });

          if (valid) {
            await dispatch(setSelectedOrganizationRole(storageRole));
            setLoading(false);
            return;
          } else {
            await storage.removeItem("selectedOrganizationRole");
          }
        }
      }

      // Set selected role as the first in the list
      await dispatch(setSelectedOrganizationRole(organizationRoles[0]));
      setLoading(false);
    })();
  }, [dispatch]);

  if (loading) {
    return <LoadingScreen />;
  }

  return children;
};

export default OrganizationLoader;
