import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BreadcrumpStateItem {
  label: string;
  link: string;
}

export interface BreadcrumpState {
  items?: BreadcrumpStateItem[];
}

const initialState: BreadcrumpState = {};

const breadcrumpSlice = createSlice({
  name: "breadcrump",
  initialState,
  reducers: {
    setBreadcrumpItems: (state, action: PayloadAction<BreadcrumpStateItem[]>) => {
      state.items = action.payload;
    },
  },
});

export const { setBreadcrumpItems } = breadcrumpSlice.actions;
export default breadcrumpSlice.reducer;
