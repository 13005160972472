import { Select } from "antd";
import { SelectProps } from "antd";
import { useEffect, useState } from "react";
import api from "../../../boot/api";
import { CommonCDNProvider, DinahOrgListCDNProvidersOutput } from "../../../lib/grpcapi";

interface Props extends SelectProps {}

const OrgCDNProviderSelect = ({ loading, onChange, value, ...otherProps }: Props) => {
  const [localLoading, setLocalLoading] = useState(false);
  const [cdnProviders, setCdnProviders] = useState<CommonCDNProvider[]>([]);

  useEffect(() => {
    (async () => {
      setLocalLoading(true);
      const res = await api.get("/org/cdn-providers");
      const data = res.data as DinahOrgListCDNProvidersOutput;
      setCdnProviders(data.CDNProviders || []);
      setLocalLoading(false);
    })();
  }, []);

  const options = cdnProviders.map(cdnProvider => ({
    label: cdnProvider.Name,
    value: cdnProvider.ID,
  }));

  let selectValue = undefined;
  if (value) {
    let vv = value as CommonCDNProvider;
    selectValue = {
      label: vv.Name,
      value: vv.ID,
    };
  }

  return (
    <Select
      {...otherProps}
      value={selectValue}
      loading={localLoading || loading}
      disabled={localLoading || loading}
      options={options}
      onChange={(value, options) => {
        // Retrieve provider using ID
        let provider: CommonCDNProvider | undefined;
        cdnProviders.forEach((p) => {
          if (p.ID === value) {
            provider = p;
          }
        });

        onChange && onChange(provider, options);
      }}
    />
  );
};

export default OrgCDNProviderSelect;
