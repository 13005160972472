import { Tag } from "antd";

interface Props {
  status?: string;
}

const BackendDeploymentStateLabel = ({ status }: Props) => {
  let color = "default";
  switch (status) {
    case "CREATED":
      color = "default";
      break;
    case "PENDING":
      color = "default";
      break;
    case "DEPLOYING":
      color = "processing";
      break;
    case "RUNNING":
      color = "success";
      break;
    case "UNINSTALLING":
      color = "warning";
      break;
    case "ERROR":
      color = "error";
      break;
  }

  return <Tag color={color}>{status}</Tag>;
};

export default BackendDeploymentStateLabel;
