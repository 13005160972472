import { Button, Form, Modal, Space } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../hooks";
import { CommonDomain } from "../../../lib/grpcapi";
import { orgSetDomainBlockings } from "../../../slices/app/org/domains";
import CountrySelect, { AllCountries, Country } from "../../utils/CountrySelect";

interface Props {
  domain?: CommonDomain;
  defaultCountryCode?: string;
  isOpen?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

type FormValues = {
  countries?: Country[];
};

const OrgSetDomainBlockingsDialog = ({ isOpen, onClose, onConfirm, domain, defaultCountryCode }: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const defaultCountries: Country[] = [];

    if (domain?.DomainBlockings?.length) {
      domain.DomainBlockings.forEach(db => {
        let country: Country | undefined;

        for (let i = 0; i < AllCountries.length; i++) {
          let c = AllCountries[i];
          if (c.abbreviation === db.CountryCode) {
            country = c;
            break;
          }
        }

        if (country) {
          defaultCountries.push(country);
        }
      });
    }

    if (!defaultCountries.length && defaultCountryCode) {
      let country: Country | undefined;

      for (let i = 0; i < AllCountries.length; i++) {
        let c = AllCountries[i];
        if (defaultCountryCode === c.abbreviation) {
          country = c;
          break;
        }
      }

      if (country) {
        defaultCountries.push(country);
      }
    }

    form.setFieldValue("countries", defaultCountries);
  }, [domain]);

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    try {
      await dispatch(orgSetDomainBlockings({
        domainID: domain?.ID as string,
        data: { CountryCodes: values.countries?.map(c => c.abbreviation) },
      }));
    } catch (err) {
      console.error(err);
    }

    form.resetFields();
    setLoading(false);
    onConfirm && onConfirm();
  };

  return (
    <Modal
      title="Set domain as blocked"
      open={isOpen}
      footer={null}
      onCancel={() => {
        if (!loading) {
          form.resetFields();
          onClose && onClose();
        }
      }}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        onFinish={handleSubmit}
        form={form}
        autoComplete="off"
      >
        <p>Domain name : {domain?.Name}</p>

        <br />

        <Form.Item<FormValues>
          label="Countries"
          name="countries"
        >
          <CountrySelect mode="multiple" allowClear />
        </Form.Item>

        <br />

        <Space>
          <Button disabled={loading} onClick={onClose}>Cancel</Button>
          <Button disabled={loading} htmlType="submit" type="primary">
            Save
          </Button>
        </Space>
      </Form>
    </Modal>
  );
};

export default OrgSetDomainBlockingsDialog;
