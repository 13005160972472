import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { KeycloakProfile } from "keycloak-js";
import storage from "../boot/storage";
import { CommonOrganizationRole } from "../lib/grpcapi";

export const setSelectedOrganizationRole = createAsyncThunk(
  "auth/setSelectedOrganizationRole",
  async (role: CommonOrganizationRole) => {
    const roleString = JSON.stringify(role);
    await storage.setItem("selectedOrganizationRole", roleString);
    return role;
  },
);

export interface AuthState {
  profile?: KeycloakProfile;
  selectedOrganizationRole?: CommonOrganizationRole;
  isAdmin: boolean;
}

const initialState: AuthState = {
  isAdmin: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<KeycloakProfile>) => {
      state.profile = action.payload;
    },
    setIsAdmin: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSelectedOrganizationRole.fulfilled, (state, action) => {
      if (action.payload) {
        const payload = action.payload as CommonOrganizationRole;
        state.selectedOrganizationRole = payload;
      }
    });
  },
});

export const { setProfile, setIsAdmin } = authSlice.actions;
export default authSlice.reducer;
