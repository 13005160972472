import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import adminApi from "../../../boot/admin-api";
import { AdminListCDNProvidersOutput, CommonCDNProvider } from "../../../lib/grpcapi-admin";

export const fetchCDNProviders = createAsyncThunk(
  "admin/cdnProviders/fetchCDNProviders",
  async () => {
    const { data } = await adminApi.get(`/cdn-providers`, {
      params: {
        Page: "1",
        Per: "20",
      },
    });

    return data;
  },
);

export interface CDNProvidersState {
  cdnProviders?: CommonCDNProvider[];
}

const initialState: CDNProvidersState = {};

const cdnProvidersSlice = createSlice({
  name: "cdnProviders",
  initialState,
  reducers: {
    setCDNProviders: (state, action: PayloadAction<CommonCDNProvider[]>) => {
      state.cdnProviders = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCDNProviders.fulfilled, (state, action) => {
      if (action.payload) {
        const payload = action.payload as AdminListCDNProvidersOutput;
        state.cdnProviders = payload.CDNProviders;
      }
    });
  },
});

export const { setCDNProviders } = cdnProvidersSlice.actions;
export default cdnProvidersSlice.reducer;
