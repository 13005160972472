import { Card } from "antd";
import { useCallback, useEffect, useState } from "react";
import OrgDomainsList from "../../../../components/org/domains/List";
import Padder from "../../../../components/utils/Padder";
import PageHeader from "../../../../components/utils/PageHeader";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { fetchOrgDomains } from "../../../../slices/app/org/domains";
import { setBreadcrumpItems } from "../../../../slices/breadcrump";
import styles from "./Index.module.css";

const OrgDomainsIndex = () => {
  const dispatch = useAppDispatch();
  const domains = useAppSelector(state => state.app.org.domains.domains);
  const domainsTotalCount = useAppSelector(state => state.app.org.domains.domainsTotalCount);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const fetchDomains = useCallback(async () => {
    setLoading(true);
    await dispatch(fetchOrgDomains({ Per: pageSize, Page: page }));
    setLoading(false);
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    dispatch(setBreadcrumpItems([
      {
        label: "Domains",
        link: "/app/org/domains",
      },
    ]));

    fetchDomains();
  }, [dispatch, fetchDomains]);

  return (
    <Padder>
      <Card>
        <PageHeader
          title="Domains"
          subTitle="Domains management"
        />
      </Card>

      <Card size="small" className={styles.head}>
        {domainsTotalCount} result.s
      </Card>

      <div className={styles.content}>
        <OrgDomainsList
          pagination={{
            total: domainsTotalCount,
            defaultCurrent: 1,
            current: page,
            showSizeChanger: true,
          }}
          domains={domains}
          loading={loading}
          onChange={(ev) => {
            setPageSize(ev.pageSize || 10);
            setPage(ev.current || 1);
          }}
        />
      </div>
    </Padder>
  );
};

export default OrgDomainsIndex;
