import { Button, Form, Modal, Space } from "antd";
import { useState } from "react";
import adminApi from "../../../boot/admin-api";
import { AdminAddUserToOrganizationInput, CommonKeycloakUser, CommonOrganization } from "../../../lib/grpcapi-admin";
import AdminKeycloakUsersSelect from "../keycloak-users/Select";

interface Props {
  organization?: CommonOrganization;
  isOpen?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

type FormValues = {
  keycloak_user?: CommonKeycloakUser;
};

const AddUserToOrgDialog = ({ isOpen, onClose, onConfirm, organization }: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    const payload: AdminAddUserToOrganizationInput = {
      OrganizationID: organization?.ID,
      KeycloakUserID: values.keycloak_user?.ID,
    };

    try {
      await adminApi.post(`/organizations/add-user`, payload);
    } catch (err) {
      console.error(err);
    }

    form.resetFields();
    setLoading(false);
    onConfirm && onConfirm();
  };

  return (
    <Modal
      title="Add user to organization"
      open={isOpen}
      footer={null}
      onCancel={() => {
        if (!loading) {
          form.resetFields();
          onClose && onClose();
        }
      }}
    >
      <div>
        <b>Organization</b>: <span>{organization?.Name}</span>
      </div>

      <br />

      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
        form={form}
        autoComplete="off"
      >
        <Form.Item
          label="Keycloak user"
          name="keycloak_user"
          rules={[{ required: true, message: "Please select a keycloak user!" }]}
        >
          <AdminKeycloakUsersSelect />
        </Form.Item>

        <br />

        <Space>
          <Button disabled={loading} onClick={onClose}>Cancel</Button>
          <Button disabled={loading} htmlType="submit" type="primary">
            Add user
          </Button>
        </Space>
      </Form>
    </Modal>
  );
};

export default AddUserToOrgDialog;
