import { Select, SelectProps } from "antd";
import countriesjson from "country-json/src/country-by-abbreviation.json";

export interface Country {
  country: string;
  abbreviation: string;
}

interface Props extends SelectProps {}

export const AllCountries: Country[] = countriesjson.map(c => {
  return {
    country: c.country,
    abbreviation: c.abbreviation,
  };
});

const CountrySelect = ({ onChange, value, ...otherProps }: Props) => {
  const options = AllCountries.map(c => {
    return {
      label: c.country,
      value: c.abbreviation,
    };
  });

  let selectValue: any;
  if (value) {
    // Multiple entry
    if (Array.isArray(value)) {
      selectValue = [];

      value.forEach(v => {
        let vv = v as Country;
        selectValue.push({
          label: vv.country,
          value: vv.abbreviation,
        });
      });
    } else {
      // Single entry
      let vv = value as Country;
      selectValue = {
        label: vv.country,
        value: vv.abbreviation,
      };
    }
  }

  const filterOption = (input: string, option?: any) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Select
      showSearch
      value={selectValue}
      options={options}
      onChange={(value, options) => {
        // Uniq value
        if (typeof value === "string") {
          let country: Country | undefined;

          (countriesjson || []).forEach(r => {
            if (r.abbreviation === value) {
              country = r;
            }
          });

          onChange && onChange(country, options);
          return;
        }

        // multiple values
        let countries: Country[] = [];

        (countriesjson || []).forEach(r => {
          value.forEach((v: string) => {
            if (r.abbreviation === v) {
              countries.push(r);
            }
          });
        });

        onChange && onChange(countries, options);
      }}
      filterOption={filterOption}
      {...otherProps}
    />
  );
};

export default CountrySelect;
