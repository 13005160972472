import styles from "./PageHeader.module.css";

interface Props {
  title: string;
  subTitle?: string;
  actions?: JSX.Element;
}

const PageHeader = (props: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>{props.title}</h2>
        {props.subTitle && <div className={styles.subTitle}>{props.subTitle}</div>}
      </div>

      {props.actions && (
        <div className={styles.actionsContainer}>
          {props.actions}
        </div>
      )}
    </div>
  );
};

export default PageHeader;
