import { CopyOutlined, DeleteOutlined, DownOutlined, PlusOutlined, RedoOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Input, MenuProps, message, Popconfirm, Row, Space } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../../../../boot/api";
import OrgMirrorDomainDeploymentsList from "../../../../../components/org/mirrors/DomainDeploymentsList";
import OrgGenDomainsForMirrorDialog from "../../../../../components/org/mirrors/OrgGenDomainsForMirrorDialog";
import { useAppDispatch } from "../../../../../hooks";
import { useAppSelector } from "../../../../../hooks";
import { CommonDomainDeployment } from "../../../../../lib/grpcapi";
import { orgFetchOrgMirrorDomains, OrgFetchOrgMirrorDomainsInput } from "../../../../../slices/app/org/mirrors";

const { Search } = Input;

const OrgMirrorsShowDomains = () => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const mirror = useAppSelector(state => state.app.org.mirrors.mirror);
  const mirrorDomains = useAppSelector(state => state.app.org.mirrors.mirrorDomains);
  const mirrorDomainsTotalCount = useAppSelector(state => state.app.org.mirrors.mirrorDomainsTotalCount);

  const [addDomainDialogOpen, setAddDomainDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [rowSelection, setRowSelection] = useState<CommonDomainDeployment[]>([]);
  const [removeSelectedMirrorsLoading, setRemoveSelectedMirrorsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchMirrorDomains = useCallback(async () => {
    let input: OrgFetchOrgMirrorDomainsInput = {
      id: params.id as string,
      params: { Per: pageSize, Page: page, Query: searchQuery },
    };

    await dispatch(orgFetchOrgMirrorDomains(input));
  }, [dispatch, params.id, page, pageSize, searchQuery]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchMirrorDomains();
      setLoading(false);
    })();
  }, [fetchMirrorDomains, searchQuery]);

  const removeSelectedMirrors = async () => {
    for (let i = 0; i < rowSelection.length; i++) {
      const domainDep = rowSelection[i];

      try {
        await api.delete(`/org/mirrors/${domainDep.MirrorID}/domains/${domainDep.DomainID}`);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const bulkActionsItems: MenuProps["items"] = [
    {
      key: "remove",
      label: (
        <Popconfirm
          title="Remove domains"
          description="Are you sure you want to remove selected domains from this mirror?"
          placement="left"
          onConfirm={async () => {
            if (removeSelectedMirrorsLoading) {
              return;
            }

            setRemoveSelectedMirrorsLoading(true);
            await removeSelectedMirrors();
            setRemoveSelectedMirrorsLoading(false);
            setRowSelection([]);

            setLoading(true);
            await fetchMirrorDomains();
            setLoading(false);
          }}
          okText="Yes"
          okButtonProps={{
            disabled: removeSelectedMirrorsLoading,
            loading: removeSelectedMirrorsLoading,
          }}
          cancelText="No"
          cancelButtonProps={{
            disabled: removeSelectedMirrorsLoading,
          }}
        >
          <Space>
            <DeleteOutlined />
            Remove domains
          </Space>
        </Popconfirm>
      ),
    },
    {
      key: "copyDomainNames",
      label: (
        <Space>
          <CopyOutlined />
          Copy domain names
        </Space>
      ),
      onClick: () => {
        const domainNamesStr = rowSelection.map(dp => dp.Domain?.Name).join("\r\n");
        navigator.clipboard.writeText(domainNamesStr);
        message.success("Copied to clipboard");
      },
    },
  ];

  return (
    <>
      <Row justify="center" align="middle">
        <Col span={12}>
          <Space>
            <Search
              allowClear
              placeholder="Search by domain name"
              style={{ width: 350 }}
              onSearch={(q) => setSearchQuery(q)}
            />
            <span>
              {mirrorDomainsTotalCount} result.s{rowSelection.length ? `, ${rowSelection.length} selected.s` : ""}
            </span>
          </Space>
        </Col>
        <Col span={12} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setAddDomainDialogOpen(true)}
            >
              Generate domains
            </Button>

            <Dropdown menu={{ items: bulkActionsItems }} disabled={!rowSelection.length}>
              <Button icon={<DownOutlined />} disabled>
                Bulk actions {rowSelection.length ? ` - ${rowSelection.length}` : ""}
              </Button>
            </Dropdown>

            <Button
              onClick={async () => {
                setLoading(true);

                try {
                  await fetchMirrorDomains();
                } catch (err) {
                  console.error(err);
                }

                setLoading(false);
              }}
            >
              <RedoOutlined />
            </Button>
          </Space>
        </Col>
      </Row>

      <br />

      <OrgMirrorDomainDeploymentsList
        loading={loading}
        pagination={{
          total: mirrorDomainsTotalCount,
          defaultCurrent: 1,
          current: page,
          showSizeChanger: true,
        }}
        onChange={(ev) => {
          setPageSize(ev.pageSize || 10);
          setPage(ev.current || 1);
        }}
        mirror={mirror}
        domainDeployments={mirrorDomains}
        onDomainDeploymentUpdated={async () => {
          setLoading(true);
          await fetchMirrorDomains();
          setLoading(false);
        }}
        selectedRows={rowSelection}
        onDomainDeploymentsSelect={(domainDeployments) => {
          setRowSelection(domainDeployments);
        }}
      />

      <OrgGenDomainsForMirrorDialog
        mirror={mirror}
        isOpen={addDomainDialogOpen}
        onClose={() => setAddDomainDialogOpen(false)}
        onConfirm={async () => {
          setAddDomainDialogOpen(false);

          setLoading(true);
          await fetchMirrorDomains();
          setLoading(false);

          message.success("Domain.s generation success.");
        }}
      />
    </>
  );
};

export default OrgMirrorsShowDomains;
