import axios, { InternalAxiosRequestConfig } from "axios";
import store from "../store";
import keycloak from "./keycloak";

const axiosConfig = {
  baseURL: "/api",
  timeout: 15000,
  withCredentials: false,
};

if (process.env.NODE_ENV === "development") {
  axiosConfig.baseURL = "http://localhost:9996";
}

const api = axios.create(axiosConfig);

const reqInterceptorOk = async (config: InternalAxiosRequestConfig) => {
  // Ensure auth
  await keycloak.updateToken(30);
  config.headers.set("Authorization", `Bearer ${keycloak.token}`);

  // Set selected org if any
  const state = store.getState();
  if (state?.auth?.selectedOrganizationRole?.Organization?.ID) {
    config.headers.set(
      "Grpc-Metadata-Dinah-Org",
      state.auth.selectedOrganizationRole.Organization.ID,
    );
  }

  config.headers.set("Accept", "application/json");
  return config;
};

const reqInterceptorErr = (error: any) => {
  return Promise.reject(error);
};

api.interceptors.request.use(reqInterceptorOk, reqInterceptorErr);

export default api;
