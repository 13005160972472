import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Space } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AdminBackendProvidersList from "../../../../components/admin/backend-providers/List";
import Padder from "../../../../components/utils/Padder";
import PageHeader from "../../../../components/utils/PageHeader";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { fetchBackendProviders } from "../../../../slices/app/admin/backend-providers";
import { setBreadcrumpItems } from "../../../../slices/breadcrump";
import styles from "./Index.module.css";

const AdminBackendProvidersIndex = () => {
  const dispatch = useAppDispatch();
  const backendProviders = useAppSelector(state => state.app.admin.backendProviders.backendProviders);
  const backendProvidersTotalCount = useAppSelector(state =>
    state.app.admin.backendProviders.backendProvidersTotalCount
  );

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const fetchbackendProviders = useCallback(async () => {
    setLoading(true);
    await dispatch(fetchBackendProviders({ Per: pageSize, Page: page }));
    setLoading(false);
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    dispatch(setBreadcrumpItems([
      {
        label: "Admin",
        link: "/app/admin",
      },
      {
        label: "Backend providers",
        link: "/app/admin/backend-providers",
      },
    ]));

    fetchbackendProviders();
  }, [dispatch, fetchbackendProviders]);

  return (
    <Padder>
      <Card>
        <PageHeader
          title="Backend providers"
          subTitle="Backend providers management"
          actions={
            <Space>
              <Link to="/app/admin/backend-providers/new">
                <Button type="primary" size="large" icon={<PlusOutlined />} disabled>
                  Create backend provider
                </Button>
              </Link>
            </Space>
          }
        />
      </Card>
      <div className={styles.content}>
        <AdminBackendProvidersList
          pagination={{
            total: backendProvidersTotalCount,
            defaultCurrent: 1,
            current: page,
            showSizeChanger: true,
          }}
          backendProviders={backendProviders}
          loading={loading}
          onChange={(ev) => {
            setPageSize(ev.pageSize || 10);
            setPage(ev.current || 1);
          }}
        />
      </div>
    </Padder>
  );
};

export default AdminBackendProvidersIndex;
