import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Space } from "antd";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import AdminCDNProvidersList from "../../../../components/admin/cdn-providers/List";
import Padder from "../../../../components/utils/Padder";
import PageHeader from "../../../../components/utils/PageHeader";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { fetchCDNProviders } from "../../../../slices/app/admin/cdn-providers";
import { setBreadcrumpItems } from "../../../../slices/breadcrump";
import styles from "./Index.module.css";

const AdminCDNProvidersIndex = () => {
  const dispatch = useAppDispatch();
  const cdnProviders = useAppSelector(state => (state.app.admin.cdnProviders.cdnProviders || []));

  useEffect(() => {
    dispatch(setBreadcrumpItems([
      {
        label: "Admin",
        link: "/app/admin",
      },
      {
        label: "CDN providers",
        link: "/app/admin/cdn-providers",
      },
    ]));

    dispatch(fetchCDNProviders());
  }, [dispatch]);

  return (
    <Padder>
      <Card>
        <PageHeader
          title="CDN providers"
          subTitle="CDN providers management"
          actions={
            <Space>
              <Link to="/app/admin/cdn-providers/new">
                <Button type="primary" size="large" icon={<PlusOutlined />} disabled>
                  Create CDN provider
                </Button>
              </Link>
            </Space>
          }
        />
      </Card>
      <div className={styles.content}>
        <AdminCDNProvidersList cdnProviders={cdnProviders} />
      </div>
    </Padder>
  );
};

export default AdminCDNProvidersIndex;
