import { DeleteOutlined, StopOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm, Space, Table, Tag } from "antd";
import { TableProps } from "antd/es/table";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import api from "../../../boot/api";
import { CommonDomain, CommonDomainDeployment, CommonMirror } from "../../../lib/grpcapi";
import DomainDeploymentStatusLabel from "../../shared/domain-deployments/StatusLabel";
import DateRender from "../../utils/DateRender";
import OrgSetDomainBlockingsDialog from "../domains/OrgSetDomainBlockingsDialog";

interface Props extends TableProps<CommonDomainDeployment> {
  mirror?: CommonMirror;
  domainDeployments?: CommonDomainDeployment[];
  onDomainDeploymentUpdated?: () => void;
  onDomainDeploymentsSelect?: (records: CommonDomainDeployment[]) => void;
  selectedRows?: CommonDomainDeployment[];
}

const OrgMirrorDomainDeploymentsList = ({
  mirror,
  domainDeployments,
  onDomainDeploymentUpdated,
  onDomainDeploymentsSelect,
  selectedRows,
  ...otherProps
}: Props) => {
  if (!domainDeployments) {
    return null;
  }

  const [
    selectedDomainForBlocked,
    setSelectedDomainForBlocked,
  ] = useState<CommonDomain | undefined | null>(null);
  const [setDomainAsBlockedDialogOpen, setSetDomainAsBlockedDialogOpen] = useState(false);

  const removeDep = async (dep: CommonDomainDeployment) => {
    try {
      await api.delete(`/org/mirrors/${dep.MirrorID}/domains/${dep.DomainID}`);
    } catch (err) {
      console.error(err);
    }

    onDomainDeploymentUpdated && onDomainDeploymentUpdated();
  };

  const columns: ColumnsType<CommonDomainDeployment> = [
    {
      title: "Name",
      key: "Name",
      render: (dep: CommonDomainDeployment) => {
        return dep.Domain?.Name;
      },
    },
    {
      title: "Status",
      key: "Status",
      render: (dep: CommonDomainDeployment) => {
        const r = [
          <DomainDeploymentStatusLabel key="status" status={dep.Status} />,
        ];

        if (dep.Domain?.DomainBlockings?.length) {
          const countryCodes = dep.Domain.DomainBlockings.map(b => {
            return b.CountryCode;
          });

          r.push(<Tag key="blocked" color="red">Blocked: {countryCodes.join(", ")}</Tag>);
        }

        if (dep.Domain?.Blocked) {
          r.push(<Tag key="blocked" color="warning">Blocked</Tag>);
        }

        return r;
      },
    },
    {
      title: "Provider",
      key: "Provider",
      render: (dep: CommonDomainDeployment) => {
        return dep.Domain?.DomainProvider?.Name;
      },
    },
    {
      title: "Creation date",
      key: "CreatedAt",
      render: (dep: CommonDomainDeployment) => {
        return <DateRender date={dep.CreatedAt} />;
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: 200,
      render: (dep: CommonDomainDeployment) => (
        <Space size="middle">
          <Popconfirm
            placement="left"
            title="Remove domain"
            description="Are you sure to remove this domain from this mirror?"
            onConfirm={() => removeDep(dep)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger icon={<DeleteOutlined />}>
              Remove
            </Button>
          </Popconfirm>

          <Button
            disabled={dep.Domain?.Blocked}
            type="link"
            icon={<StopOutlined />}
            onClick={() => {
              setSelectedDomainForBlocked(dep.Domain);
              setSetDomainAsBlockedDialogOpen(true);
            }}
          >
            Set as blocked
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        bordered
        rowKey="ID"
        columns={columns}
        dataSource={domainDeployments}
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: (selectedRows || []).map(dp => dp.ID as string),
          onChange: (_, records) => {
            onDomainDeploymentsSelect && onDomainDeploymentsSelect(records);
          },
        }}
        {...otherProps}
      />

      {selectedDomainForBlocked && (
        <OrgSetDomainBlockingsDialog
          defaultCountryCode={mirror?.CountryCode}
          domain={selectedDomainForBlocked}
          isOpen={setDomainAsBlockedDialogOpen}
          onClose={() => setSetDomainAsBlockedDialogOpen(false)}
          onConfirm={() => {
            setSetDomainAsBlockedDialogOpen(false);
            onDomainDeploymentUpdated && onDomainDeploymentUpdated();
            message.success("Blockings saved successfully");
          }}
        />
      )}
    </>
  );
};

export default OrgMirrorDomainDeploymentsList;
