import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../boot/api";
import {
  CommonDomain,
  DinahOrgListDomainsOutput,
  DinahOrgSetDomainBlockingsBody,
  DinahOrgSetDomainBlockingsOutput,
} from "../../../lib/grpcapi";

export const fetchOrgDomains = createAsyncThunk(
  "org/domains/fetchOrgDomains",
  async (params: any): Promise<DinahOrgListDomainsOutput> => {
    const { data } = await api.get(`/org/domains`, { params });
    return data;
  },
);

export const orgSetDomainBlockings = createAsyncThunk(
  "org/domains/orgSetDomainBlockings",
  async (params: { domainID: string; data: DinahOrgSetDomainBlockingsBody }): Promise<{
    domainID: string;
    data: DinahOrgSetDomainBlockingsOutput;
  }> => {
    const { data } = await api.post(
      `/org/domains/${params.domainID}/blockings`,
      params.data,
    );
    return { domainID: params.domainID, data };
  },
);

export interface DomainsState {
  domains?: CommonDomain[];
  domainsTotalCount?: number;
}

const initialState: DomainsState = {};

const domainsSlice = createSlice({
  name: "domains",
  initialState,
  reducers: {
    setDomains: (state, action: PayloadAction<CommonDomain[]>) => {
      state.domains = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrgDomains.fulfilled, (state, action) => {
      if (action.payload) {
        state.domains = action.payload.Domains;
        state.domainsTotalCount = parseInt(action.payload.TotalCount || "") || 0;
      }
    });

    builder.addCase(orgSetDomainBlockings.fulfilled, (state, action) => {
      if (action.payload) {
        const domainID = action.payload.domainID;
        const payload = action.payload.data;

        state.domains = (state.domains || []).map((domain) => {
          if (domain.ID === domainID) {
            domain.DomainBlockings = payload.DomainBlokings;
          }

          return domain;
        });
      }
    });
  },
});

export const { setDomains } = domainsSlice.actions;
export default domainsSlice.reducer;
