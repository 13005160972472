import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import adminApi from "../../../boot/admin-api";
import {
  AdminGetDomainOutput,
  AdminListDomainsOutput,
  AdminSetDomainBlockingsOutput,
  CommonDomain,
  CommonOrganization,
  DinahAdminSetDomainBlockingsBody,
  DinahAdminSetDomainStatusBody,
} from "../../../lib/grpcapi-admin";

export const adminFetchDomains = createAsyncThunk(
  "admin/domains/adminFetchDomains",
  async (params: any) => {
    const { data } = await adminApi.get(`/domains`, { params });
    return data;
  },
);

export const adminFetchDomain = createAsyncThunk(
  "admin/domains/adminFetchDomain",
  async (id: string) => {
    const { data } = await adminApi.get(`/domains/${id}`);
    return data;
  },
);

// post: "/admin/domains/{DomainID}/organization"
export const setDomainOrganization = createAsyncThunk(
  "admin/domains/setDomainOrganization",
  async (input: { organization: CommonOrganization; domain: CommonDomain }) => {
    const payload = { OrganizationID: input.organization.ID };
    await adminApi.post(`/domains/${input.domain.ID}/organization`, payload);
    return input;
  },
);

// post: "/admin/domains/{DomainID}/status"
export const setDomainStatus = createAsyncThunk(
  "admin/domains/setDomainStatus",
  async (input: { id: string; status: string }) => {
    const payload: DinahAdminSetDomainStatusBody = {
      Status: input.status,
    };
    await adminApi.post(`/domains/${input.id}/status`, payload);
    return input;
  },
);

export const adminSetDomainBlockings = createAsyncThunk(
  "admin/domains/adminSetDomainBlockings",
  async (params: { ID: string; data: DinahAdminSetDomainBlockingsBody }): Promise<{
    domainID: string;
    data: AdminSetDomainBlockingsOutput;
  }> => {
    const { data } = await adminApi.post(
      `/domains/${params.ID}/blockings`,
      params.data,
    );
    return { domainID: params.ID, data };
  },
);

export interface DomainsState {
  domains?: CommonDomain[];
  domainsTotalCount?: number;
  domain?: CommonDomain;
}

const initialState: DomainsState = {};

const domainsSlice = createSlice({
  name: "domains",
  initialState,
  reducers: {
    setDomains: (state, action: PayloadAction<CommonDomain[]>) => {
      state.domains = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminFetchDomains.fulfilled, (state, action) => {
      if (action.payload) {
        const payload = action.payload as AdminListDomainsOutput;
        state.domains = payload.Domains;
        state.domainsTotalCount = parseInt(payload.TotalCount || "0");
      }
    });

    builder.addCase(adminFetchDomain.fulfilled, (state, action) => {
      if (action.payload) {
        const payload = action.payload as AdminGetDomainOutput;
        state.domain = payload.Domain;
      }
    });

    builder.addCase(setDomainOrganization.fulfilled, (state, action) => {
      if (action.payload) {
        const payload = action.payload as any;
        const payloadDomain = payload.domain as CommonDomain;
        const payloadOrg = payload.domain as CommonOrganization;

        if (state.domain) {
          if (state.domain?.ID === payloadDomain.ID) {
            state.domain.OrganizationID = payloadOrg.ID;
            state.domain.Organization = payloadOrg;
          }
        }

        state.domain = payload.Domain;
      }
    });

    builder.addCase(setDomainStatus.fulfilled, (state, action) => {
      if (action.payload) {
        const payload = action.payload as any;
        const domainId = payload.id as string;
        const payloadStatus = payload.status as string;

        if (state.domain) {
          if (state.domain?.ID === domainId) {
            state.domain.Status = payloadStatus;
          }
        }

        state.domains = (state.domains || []).map(domain => {
          if (domain) {
            if (domain?.ID === domainId) {
              domain.Status = payloadStatus;
            }
          }

          return domain;
        });
      }
    });

    builder.addCase(adminSetDomainBlockings.fulfilled, (state, action) => {
      if (action.payload) {
        const domainID = action.payload.domainID;
        const payload = action.payload.data;

        state.domains = (state.domains || []).map((domain) => {
          if (domain.ID === domainID) {
            domain.DomainBlockings = payload.DomainBlokings;
          }

          return domain;
        });

        if (state.domain?.ID === domainID) {
          state.domain.DomainBlockings = payload.DomainBlokings;
        }
      }
    });
  },
});

export const { setDomains } = domainsSlice.actions;
export default domainsSlice.reducer;
