import { StopOutlined } from "@ant-design/icons";
import { Button, Descriptions, Divider, List, message, Popconfirm, Typography } from "antd";
import { useState } from "react";
import api from "../../../../../boot/api";
import BackendDeploymentStateLabel from "../../../../../components/shared/backend-deployments/StateLabel";
import CDNDeploymentStateLabel from "../../../../../components/shared/cdn-deployments/StateLabel";
import MirrorStateLabel from "../../../../../components/shared/mirrors/StateLabel";
import DateRender from "../../../../../components/utils/DateRender";
import { useAppSelector } from "../../../../../hooks";
import { CommonDomainDeployment } from "../../../../../lib/grpcapi";

const { Text } = Typography;

const OrgMirrorsShowInformations = () => {
  const [purgeLoading, setPurgeLoading] = useState(false);
  const mirror = useAppSelector(state => state.app.org.mirrors.mirror);

  const handlePurge = async () => {
    setPurgeLoading(true);

    try {
      await api.post(`/org/mirrors/${mirror?.ID}/cdn/purge-cache`);
      message.success("Purge request completed");
    } catch (err) {
      console.error(err);
      message.error("Error on purge request");
    }

    setTimeout(() => {
      setPurgeLoading(false);
    }, 500);
  };

  return (
    <>
      <Descriptions title="Mirror">
        <Descriptions.Item label="Status">
          <MirrorStateLabel status={mirror?.Status} />
        </Descriptions.Item>
        <Descriptions.Item label="Name">
          {mirror?.Name}
        </Descriptions.Item>
        <Descriptions.Item label="Key">
          <Text copyable>{mirror?.Key}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Creation date">
          <DateRender date={mirror?.CreatedAt} />
        </Descriptions.Item>
        <Descriptions.Item label="Update date">
          <DateRender date={mirror?.UpdatedAt} />
        </Descriptions.Item>
        <Descriptions.Item label="Private ?">
          {mirror?.Private ? "Yes" : "No"}
        </Descriptions.Item>
        <Descriptions.Item label="Countries">
          {mirror?.CountryCodes?.join(", ")}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="Backend">
        <Descriptions.Item label="Status">
          <BackendDeploymentStateLabel status={mirror?.BackendDeployment?.Status} />
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="CDN">
        <Descriptions.Item label="Status">
          <CDNDeploymentStateLabel status={mirror?.CDNDeployment?.Status} />

          {mirror?.CDNDeployment?.Status === "RUNNING" && (
            <Popconfirm
              title="Purge CDN cache"
              description="Confirm purging the CDN cache ?"
              okText="Yes"
              cancelText="Cancel"
              onConfirm={() => {
                handlePurge();
              }}
            >
              <Button
                disabled={purgeLoading}
                icon={<StopOutlined />}
                danger
                type="link"
              >
                Purge cache
              </Button>
            </Popconfirm>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="CDN Deployment URL">
          {(mirror?.CDNDeployment?.InternalFQDNs || []).map(u => (
            <div key={u}>
              <a target="_blank" rel="noreferrer" href={"http://" + u}>{u}</a>
              <br />
            </div>
          ))}
        </Descriptions.Item>

        <Descriptions.Item label="Domains">
          {(mirror?.DomainDeployments || []).map(dp => (
            <div key={dp.ID}>
            </div>
          ))}
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      <List<CommonDomainDeployment>
        header="Domains"
        bordered
        dataSource={mirror?.DomainDeployments || []}
        renderItem={(dp: CommonDomainDeployment) => {
          return (
            <List.Item>
              <a target="_blank" rel="noreferrer" href={"http://" + dp.Domain?.Name}>{dp.Domain?.Name}</a>
            </List.Item>
          );
        }}
      />
    </>
  );
};

export default OrgMirrorsShowInformations;
