import { Select } from "antd";
import { useEffect, useState } from "react";
import adminApi from "../../../boot/admin-api";
import { AdminListKeycloakUsersOutput, CommonKeycloakUser } from "../../../lib/grpcapi-admin";
import styles from "./Select.module.css";

interface Props {
  disabled?: boolean;
  onChange?: (user: CommonKeycloakUser | undefined) => void;
}

const AdminKeycloakUsersSelect = ({ onChange, disabled }: Props) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<CommonKeycloakUser[]>([]);

  const fetchUsers = async (query?: string) => {
    setLoading(true);
    try {
      const params = { Query: query };
      const res = await adminApi.get("/keycloak-users", { params });
      const data = res.data as AdminListKeycloakUsersOutput;
      setItems(data.KeycloakUsers || []);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    // Fetch domain providers
    fetchUsers();
  }, []);

  const handleChange = (val: any) => {
    let user: CommonKeycloakUser | undefined;

    items.forEach(i => {
      if (i.ID === val) {
        user = i;
      }
    });

    onChange && onChange(user);
  };

  const handleSearch = (value: string) => {
    fetchUsers(value);
  };

  const options = items.map(user => {
    return {
      label: user.Username,
      value: user.ID,
    };
  });

  return (
    <Select<CommonKeycloakUser>
      allowClear
      showSearch
      loading={loading}
      disabled={disabled}
      className={styles.select}
      options={options}
      onChange={handleChange}
      onSearch={handleSearch}
      filterOption={false}
    />
  );
};

export default AdminKeycloakUsersSelect;
