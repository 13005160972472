import { combineReducers } from "@reduxjs/toolkit";

import admin from "./admin";
import org from "./org";
import user from "./user";

const app = {
  admin: combineReducers(admin),
  user: combineReducers(user),
  org: combineReducers(org),
};

export default app;
