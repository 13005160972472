import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Descriptions, message, Space, Spin } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AdminSetDomainBlockingsDialog from "../../../../components/admin/domains/AdminSetDomainBlockingsDialog";
import AdminAssignDomainToOrgDialog from "../../../../components/admin/domains/AssignToOrgDialog";
import Padder from "../../../../components/utils/Padder";
import PageHeader from "../../../../components/utils/PageHeader";
import { useAppSelector } from "../../../../hooks";
import { useAppDispatch } from "../../../../hooks";
import { adminFetchDomain, setDomainStatus } from "../../../../slices/app/admin/domains";
import styles from "./Show.module.css";

const AppDomainShow = () => {
  const params = useParams();
  const navigate = useNavigate();

  const domain = useAppSelector(state => state.app.admin.domains.domain);
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [changeStatusLoading, setChangeStatusLoading] = useState<boolean>(false);
  const [assignOrgModalOpen, setAssignOrgModalOpen] = useState<boolean>(false);
  const [domainBlockingsDialogOpen, setDomainBlockingsDialogOpen] = useState(false);

  const fetchDomain = useCallback(async () => {
    setLoading(true);
    await dispatch(adminFetchDomain(params.id as string));
    setLoading(false);
  }, [dispatch, params.id]);

  useEffect(() => {
    (async () => {
      try {
        await fetchDomain();
      } catch (err) {
        console.error(err);
      }

      setLoading(false);
    })();
  }, [params.id, dispatch, fetchDomain]);

  const toggleDomainStatus = async () => {
    if (!domain || (domain && !domain.ID)) {
      return;
    }

    setChangeStatusLoading(true);

    const status = domain.Status === "OK" ? "DEAD" : "OK";
    const id = domain.ID as string;

    try {
      await dispatch(setDomainStatus({
        id,
        status,
      }));

      message.success("Domain status changed");
    } catch (err) {
      message.error("Failed to change domain status");
      console.error(err);
    }

    setChangeStatusLoading(false);
  };

  if (loading) {
    return <Spin />;
  }

  if (!domain) {
    return <div>Error whil loading domain</div>;
  }

  return (
    <Padder>
      <Card>
        <PageHeader
          title={`Domain: ${domain ? domain.Name : "--"}`}
          subTitle="Domain details"
          actions={
            <Space>
              <Button
                disabled={changeStatusLoading}
                onClick={() => setDomainBlockingsDialogOpen(true)}
              >
                Set domain blockings
              </Button>

              <Button
                disabled={changeStatusLoading}
                onClick={() => toggleDomainStatus()}
              >
                Change status to {domain.Status === "OK" ? "DEAD" : "OK"}
              </Button>

              <Button
                disabled={changeStatusLoading}
                onClick={() => navigate(-1)}
              >
                Go back
              </Button>
              <Button
                type="primary"
                disabled={changeStatusLoading}
                icon={<PlusOutlined />}
                onClick={() => setAssignOrgModalOpen(true)}
              >
                Assign organization
              </Button>
            </Space>
          }
        />
      </Card>
      <div className={styles.content}>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-xs-12">
            <Card title="Informations">
              <Descriptions>
                <Descriptions.Item label="Name">
                  {domain.Name}
                </Descriptions.Item>
                <Descriptions.Item label="Public">
                  {domain.Public ? "Yes" : "No"}
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  {domain.Status}
                </Descriptions.Item>
                <Descriptions.Item label="Domain provider">
                  {domain.DomainProvider?.Name}
                </Descriptions.Item>
                <Descriptions.Item label="Blockings">
                  {domain.DomainBlockings?.map(b => b.CountryCode).join(", ")}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </div>
        </div>
      </div>

      <AdminAssignDomainToOrgDialog
        domain={domain}
        isOpen={assignOrgModalOpen}
        onClose={() => setAssignOrgModalOpen(false)}
        onConfirm={() => {
          setAssignOrgModalOpen(false);
          message.success("Domain assigned");
          fetchDomain();
        }}
      />

      <AdminSetDomainBlockingsDialog
        domain={domain}
        isOpen={domainBlockingsDialogOpen}
        onClose={() => setDomainBlockingsDialogOpen(false)}
        onConfirm={() => {
          setDomainBlockingsDialogOpen(false);
          // onDomainDeploymentUpdated && onDomainDeploymentUpdated();
          message.success("Blockings saved successfully");
        }}
      />
    </Padder>
  );
};

export default AppDomainShow;
