import { Button, Form, Input, Modal, Space } from "antd";
import { useState } from "react";
import { useAppDispatch } from "../../../hooks";
import { CommonMirror } from "../../../lib/grpcapi";
import { orgRenameMirror } from "../../../slices/app/org/mirrors";

interface Props {
  mirror?: CommonMirror;
  isOpen?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

type FormValues = {
  name?: string;
};

const OrgRenameMirrorDialog = ({ isOpen, onClose, onConfirm, mirror }: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    try {
      await dispatch(orgRenameMirror({
        name: values.name as string,
        mirrorID: mirror?.ID as string,
      }));
    } catch (err) {
      console.error(err);
    }

    form.resetFields();
    setLoading(false);
    onConfirm && onConfirm();
  };

  return (
    <Modal
      title="Rename mirror"
      open={isOpen}
      footer={null}
      onCancel={() => {
        if (!loading) {
          form.resetFields();
          onClose && onClose();
        }
      }}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ name: mirror?.Name }}
        onFinish={handleSubmit}
        form={form}
        autoComplete="off"
      >
        <Form.Item<FormValues>
          label="Name"
          name="name"
          rules={[
            { required: true, message: "Name required" },
            { max: 64, message: "Max 64 char len" },
          ]}
        >
          <Input />
        </Form.Item>

        <br />

        <Space>
          <Button disabled={loading} onClick={onClose}>Cancel</Button>
          <Button disabled={loading} htmlType="submit" type="primary">
            Rename mirror
          </Button>
        </Space>
      </Form>
    </Modal>
  );
};

export default OrgRenameMirrorDialog;
