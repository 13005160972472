import { Alert, Button, Card, Descriptions, Divider, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import adminApi from "../../../../boot/admin-api";
import Padder from "../../../../components/utils/Padder";
import PageHeader from "../../../../components/utils/PageHeader";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { AdminCheckDomainProviderConfigInput } from "../../../../lib/grpcapi-admin";
import { fetchDomainProvider } from "../../../../slices/app/admin/domain-providers";
import styles from "./Show.module.css";

const AppDomainproviderShow = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const domainProvider = useAppSelector(state => state.app.admin.domainProviders.domainProvider);

  const [loading, setLoading] = useState<boolean>(false);
  const [checkLoading, setCheckLoading] = useState<boolean>(false);
  const [checkSuccess, setCheckSuccess] = useState<boolean | undefined>();
  const [checkMessage, setCheckMessage] = useState<string | undefined>();

  useEffect(() => {
    (async () => {
      try {
        await dispatch(fetchDomainProvider(params.id as string));
      } catch (err) {
        console.error(err);
      }

      setLoading(false);
    })();
  }, [params.id, dispatch]);

  if (loading) {
    return <Spin />;
  }

  if (!domainProvider) {
    return <div>Error whil loading domain provider</div>;
  }

  const checkConfig = async () => {
    setCheckLoading(true);

    const payload: AdminCheckDomainProviderConfigInput = {
      Vendor: domainProvider.Vendor,
      Config: domainProvider.Config,
    };

    try {
      const { data } = await adminApi.post("/domain-providers/check-config", payload);

      if (data && data.Message) {
        setCheckSuccess(data.Success as boolean);
        setCheckMessage(data.Message as string);
      } else {
        setCheckSuccess(false);
        setCheckMessage("Uknown error");
      }
    } catch (err) {
      console.error(err);
    }

    setCheckLoading(false);
  };

  // Check callout result
  let checkCalloutResult = <Alert message="Config check result" />;
  if (checkLoading) {
    checkCalloutResult = <Alert type="warning" message="Check loading" />;
  }
  if (checkSuccess === false) {
    checkCalloutResult = (
      <Alert
        type="error"
        message={
          <>
            Error while checking config :

            <br />
            <br />

            <code>
              {checkMessage}
            </code>
          </>
        }
      />
    );
  }
  if (checkSuccess === true) {
    checkCalloutResult = <Alert type="success" message="Config OK" />;
  }

  return (
    <Padder>
      <Card>
        <PageHeader
          title={`Domain provider: ${domainProvider ? domainProvider.Name : "--"}`}
          subTitle="Domain provider details"
          actions={
            <Space>
              <Button onClick={() => navigate(-1)}>
                Go Back
              </Button>
            </Space>
          }
        />
      </Card>

      <div className={styles.content}>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-xs-12">
            <Card title="Informations">
              <Descriptions>
                <Descriptions.Item label="name">
                  {domainProvider.Name}
                </Descriptions.Item>
                <Descriptions.Item label="Type">
                  {domainProvider.Vendor}
                </Descriptions.Item>
              </Descriptions>

              <Divider />

              <>
                <Button
                  disabled={checkLoading}
                  type="primary"
                  onClick={() => checkConfig()}
                >
                  Check config
                </Button>

                <br />
                <br />

                {checkCalloutResult}
              </>
            </Card>
          </div>
        </div>
      </div>
    </Padder>
  );
};

export default AppDomainproviderShow;
