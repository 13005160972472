import { Select } from "antd";

import { CommonOrganizationRole } from "../../../lib/grpcapi";
import styles from "./RoleSelect.module.css";

interface Props {
  disabled?: boolean;
  onChange?: (role: CommonOrganizationRole) => void;
  roles?: CommonOrganizationRole[];
  selectedRole?: CommonOrganizationRole;
}

const OrganizationRoleSelect = ({ onChange, disabled, roles, selectedRole }: Props) => {
  const options = (roles || []).map(role => {
    return {
      label: role.Organization?.Name,
      value: role.ID,
    };
  });

  const handleChange = (orgID: string) => {
    let org: CommonOrganizationRole | undefined;

    (roles || []).forEach(r => {
      if (r.ID === orgID) {
        org = r;
      }
    });

    onChange && onChange(org as CommonOrganizationRole);
  };

  let selectValue: string | undefined;
  if (selectedRole && selectedRole.ID) {
    selectValue = selectedRole.ID;
  }

  return (
    <Select
      size="large"
      className={styles.select}
      options={options}
      value={selectValue}
      onChange={handleChange}
      disabled={disabled}
    />
  );
};

export default OrganizationRoleSelect;
