import { Select, SelectProps } from "antd";
import { useCallback, useEffect, useState } from "react";
import adminApi from "../../../boot/admin-api";
import { AdminListOrganizationsOutput, CommonOrganization } from "../../../lib/grpcapi-admin";

interface Props extends SelectProps {
  onChange?: (user: CommonOrganization | undefined) => void;
}

const AdminOrganizationsSelect = ({ onChange, disabled }: Props) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<CommonOrganization[]>([]);

  const fetchOrgs = useCallback(async (query?: string) => {
    setLoading(true);

    try {
      const params = { Query: query };
      const res = await adminApi.get("/organizations", { params });
      const data = res.data as AdminListOrganizationsOutput;
      setItems(data.Organizations || []);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    // Fetch organizations
    fetchOrgs();
  }, [fetchOrgs]);

  const handleChange = (val: any) => {
    let org: CommonOrganization | undefined;

    items.forEach(i => {
      if (i.ID === val) {
        org = i;
      }
    });

    onChange && onChange(org);
  };

  const handleSearch = (value: string) => {
    fetchOrgs(value);
  };

  const options = items.map(domain => {
    return {
      label: domain.Name,
      value: domain.ID,
    };
  });

  return (
    <Select<CommonOrganization>
      allowClear
      showSearch
      loading={loading}
      disabled={disabled}
      options={options}
      onChange={handleChange}
      onSearch={handleSearch}
      filterOption={false}
    />
  );
};

export default AdminOrganizationsSelect;
