import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import adminApi from "../../../boot/admin-api";
import { AdminListBackendProvidersOutput, CommonBackendProvider } from "../../../lib/grpcapi-admin";

export const fetchBackendProviders = createAsyncThunk(
  "admin/backendProviders/fetchBackendProviders",
  async (params: any) => {
    const { data } = await adminApi.get(`/backend-providers`, { params });

    return data;
  },
);

export interface BackendProvidersState {
  backendProviders?: CommonBackendProvider[];
  backendProvidersTotalCount?: number;
}

const initialState: BackendProvidersState = {};

const backendProvidersSlice = createSlice({
  name: "backendProviders",
  initialState,
  reducers: {
    setBackendProviders: (state, action: PayloadAction<CommonBackendProvider[]>) => {
      state.backendProviders = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBackendProviders.fulfilled, (state, action) => {
      if (action.payload) {
        const payload = action.payload as AdminListBackendProvidersOutput;
        state.backendProviders = payload.BackendProviders;
        state.backendProvidersTotalCount = parseInt(payload.TotalCount || "") || 0;
      }
    });
  },
});

export const { setBackendProviders } = backendProvidersSlice.actions;
export default backendProvidersSlice.reducer;
