import { Button, Form, Modal, Space } from "antd";
import { useState } from "react";
import adminApi from "../../../boot/admin-api";
import { CommonDomain, CommonOrganization } from "../../../lib/grpcapi-admin";
import AdminOrganizationsSelect from "../organizations/Select";

interface Props {
  domain: CommonDomain;
  isOpen?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

type FormValues = {
  organization?: CommonOrganization;
};

const AdminAssignDomainToOrgDialog = ({ isOpen, onClose, onConfirm, domain }: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    const payload = { OrganizationID: values.organization?.ID };

    try {
      await adminApi.post(`/domains/${domain?.ID}/organization`, payload);
    } catch (err) {
      console.error(err);
    }

    form.resetFields();
    setLoading(false);
    onConfirm && onConfirm();
  };

  return (
    <Modal
      title="Assign domain to organization"
      open={isOpen}
      footer={null}
      onCancel={() => {
        if (!loading) {
          form.resetFields();
          onClose && onClose();
        }
      }}
    >
      <div>
        <b>Domain</b>: <span>{domain?.Name}</span>
      </div>

      <br />

      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={handleSubmit}
        form={form}
        autoComplete="off"
      >
        <Form.Item
          label="Organization"
          name="organization"
          rules={[{ required: true, message: "Please select an organization!" }]}
        >
          <AdminOrganizationsSelect />
        </Form.Item>

        <br />

        <Space>
          <Button disabled={loading} onClick={onClose}>Cancel</Button>
          <Button disabled={loading} htmlType="submit" type="primary">
            Assign
          </Button>
        </Space>
      </Form>
    </Modal>
  );
};

export default AdminAssignDomainToOrgDialog;
