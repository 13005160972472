import {
  BlockOutlined,
  BugOutlined,
  LockOutlined,
  LogoutOutlined,
  MailOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Divider, Layout, Menu, MenuProps, Space, Tooltip, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import logo from "../../assets/logo.svg";
import keycloak from "../../boot/keycloak";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { CommonOrganizationRole } from "../../lib/grpcapi";
import { setSelectedOrganizationRole } from "../../slices/auth";
import OrganizationRoleSelect from "../user/organizations/RoleSelect";
import AppBreadcrump from "../utils/AppBreadcrump";
import styles from "./AppLayout.module.css";

const { Header, Content, Sider } = Layout;
const { Text } = Typography;

const AppLayout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(state => state.auth.profile);
  const isAdmin = useAppSelector(state => state.auth.isAdmin);
  const organizationRoles = useAppSelector(state => state.app.user.organizations.organizations);
  const selectedOrganizationRole = useAppSelector(state => state.auth.selectedOrganizationRole);

  const signOut = () => {
    keycloak.logout();
  };

  const goToProfile = () => {
    keycloak.accountManagement();
  };

  const handleRoleChange = async (role: CommonOrganizationRole) => {
    await dispatch(setSelectedOrganizationRole(role));
    navigate("/");

    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  const siderMenuItems: MenuProps["items"] = [
    {
      label: (
        <Link to="/app/org/mirrors">
          Mirrors
        </Link>
      ),
      key: "mirrors",
      icon: <BlockOutlined />,
    },
    {
      label: (
        <Link to="/app/org/domains">
          Domains
        </Link>
      ),
      key: "domains",
      icon: <UnorderedListOutlined />,
    },
  ];

  if (isAdmin) {
    const adminSiderMenuItems = [
      {
        label: "Admin",
        key: "admin",
        icon: <LockOutlined />,
        children: [
          {
            label: (
              <Link to="/app/admin/organizations">
                Organizations
              </Link>
            ),
            key: "admin-organizations",
            icon: <MailOutlined />,
          },
          {
            label: (
              <Link to="/app/admin/domains">
                Domains
              </Link>
            ),
            key: "admin-domains",
            icon: <MailOutlined />,
          },
          {
            label: (
              <Link to="/app/admin/domain-providers">
                Domain providers
              </Link>
            ),
            key: "admin-domain-providers",
            icon: <MailOutlined />,
          },
          {
            label: (
              <Link to="/app/admin/backend-providers">
                Backend providers
              </Link>
            ),
            key: "admin-backend-providers",
            icon: <MailOutlined />,
          },
          {
            label: (
              <Link to="/app/admin/cdn-providers">
                CDN providers
              </Link>
            ),
            key: "admin-cdn-providers",
            icon: <MailOutlined />,
          },
        ],
      },
    ];

    siderMenuItems.push(...adminSiderMenuItems);
  }

  const alicexVersion = process.env.REACT_APP_ALICEX_VERSION || "dev";
  // const alicexRev = process.env.REACT_APP_ALICEX_REV || "dev";

  return (
    <Layout hasSider>
      <Sider
        theme="light"
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div className={styles.siderHeader}>
          <Link to="/">
            <div className={styles.siderAppName}>
              <img alt="Logo" src={logo} /> Dinah
            </div>
          </Link>
        </div>
        <div className={styles.siderContent}>
          <Menu
            className={styles.siderMenu}
            theme="light"
            mode="inline"
            items={siderMenuItems}
            defaultOpenKeys={["admin"]}
          />

          <div className={styles.siderBottom}>
            <Text type="secondary">
              Made by <a target="_blank" rel="noreferrer" href="https://rsf.org/">RSF</a>
            </Text>
            <br />
            <Text type="secondary">
              Version: {alicexVersion}
            </Text>
          </div>
        </div>
      </Sider>
      <Layout style={{ marginLeft: 200, background: "#E7ECEE" }}>
        <Header className={styles.header}>
          <div className={styles.headerLeftContainer}>
            <div className={styles.roleSelectContainer}>
              <OrganizationRoleSelect
                roles={organizationRoles}
                selectedRole={selectedOrganizationRole}
                onChange={(role) => handleRoleChange(role)}
              />
            </div>

            <AppBreadcrump />
          </div>

          <div className={styles.headerRightContainer}>
            <Space>
              <Tooltip title="Edit profile">
                <Button onClick={() => goToProfile()}>
                  <Avatar size="small" icon={<UserOutlined />} />
                  <span style={{ fontWeight: "bold", marginLeft: "8px" }}>{profile?.username}</span>
                </Button>
              </Tooltip>
              <Button onClick={() => signOut()} icon={<LogoutOutlined />}>Sign out</Button>
              <Divider type="vertical" />
              <Button type="text" icon={<BugOutlined />}>
                <a href="mailto:bugreport-dinah@rsf.org">Report a bug</a>
              </Button>
            </Space>
          </div>
        </Header>

        <Content style={{ overflow: "initial" }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
