import AuthLoader from "./components/loaders/AuthLoader";
import OrganizationsLoader from "./components/loaders/OrganizationsLoader";
import "./App.css";
import ErrorBoundary from "./components/utils/ErrorBoundary";
import routes from "./routes";

function App() {
  return (
    <ErrorBoundary>
      <AuthLoader>
        <OrganizationsLoader>
          {routes}
        </OrganizationsLoader>
      </AuthLoader>
    </ErrorBoundary>
  );
}

export default App;
