import { EyeOutlined } from "@ant-design/icons";
import { Button, Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { TableProps } from "antd/es/table";
import { Link } from "react-router-dom";
import { CommonMirror } from "../../../lib/grpcapi";
import MirrorStateLabel from "../../shared/mirrors/StateLabel";
import DateRender from "../../utils/DateRender";

const { Text, Title } = Typography;

interface Props extends TableProps<CommonMirror> {
  mirrors?: CommonMirror[];
}

const OrgMirrorsList = ({ mirrors, ...otherProps }: Props) => {
  if (!mirrors) {
    return null;
  }

  const columns: ColumnsType<CommonMirror> = [
    {
      title: "Name",
      key: "Name",
      render: (mirror: CommonMirror) => {
        return (
          <div>
            <Title level={5} style={{ margin: 0 }}>{mirror.Name}</Title>
            <Text copyable type="secondary">{mirror.Key}</Text>
          </div>
        );
      },
    },
    {
      title: "Countries",
      key: "Countries",
      render: (mirror: CommonMirror) => {
        return mirror.CountryCodes?.join(", ");
      },
    },
    {
      title: "Private",
      key: "Private",
      render: (mirror: CommonMirror) => {
        if (mirror.Private) {
          return "Yes";
        }

        return "No";
      },
    },
    {
      title: "Status",
      key: "Status",
      render: (mirror: CommonMirror) => {
        return <MirrorStateLabel status={mirror.Status} />;
      },
    },
    {
      title: "Last update date",
      key: "UpdatedAt",
      render: (mirror: CommonMirror) => {
        return <DateRender date={mirror.UpdatedAt} />;
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: 200,
      render: (mirror) => (
        <Space size="middle">
          <Link to={`/app/org/mirrors/${mirror.ID}`}>
            <Button type="link" icon={<EyeOutlined />}>Show</Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <Table<CommonMirror>
      bordered
      rowKey="ID"
      columns={columns}
      dataSource={mirrors}
      {...otherProps}
    />
  );
};

export default OrgMirrorsList;
