import { Space, Tooltip } from "antd";
import dayjs from "dayjs";

interface Props {
  date: any;
  format?: string;
}

const DateRender = ({ date, format = "DD/MM/YYYY HH:mm" }: Props) => {
  let finalDate = date;

  // Try to convert unix timestamp presented as string
  if (typeof date === "string") {
    let res = parseInt(date);
    if (!Number.isNaN(res)) {
      finalDate = res;
    }
  }

  return (
    <Space>
      <Tooltip placement="top" title={dayjs(finalDate).fromNow()}>
        {dayjs(finalDate).format(format)}
      </Tooltip>
    </Space>
  );
};

export default DateRender;
