import { combineReducers } from "@reduxjs/toolkit";
import app from "./app";
import auth from "./auth";
import breadcrump from "./breadcrump";

const reducers = {
  auth,
  breadcrump,
  app: combineReducers(app),
};

export default reducers;
