import dayjs from "dayjs";
import dayjsRelativeTime from "dayjs/plugin/relativeTime";
import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

import "./index.css";
import "normalize.css";
import "flexboxgrid/css/flexboxgrid.css";

// Condigure sentry
if (process.env.NODE_ENV !== "development") {
  (async () => {
    const Sentry = await import("@sentry/react");

    // @TODO: sentry dsn in config
    Sentry.init({
      dsn: "https://f0e9bb7fe73430f735f5006f23226c27@o4506033070080000.ingest.sentry.io/4506033381441536",
      integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration(),
        Sentry.captureConsoleIntegration({
          levels: ["error"],
        }),
      ],
      tracesSampleRate: 1.0,
    });
  })();
}

dayjs.extend(dayjsRelativeTime);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

// Do not use strict mode, it render comps two times
// https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
