import { Button, Card, Form, Input } from "antd";
import { useState } from "react";
import { CommonDomainProviderVendor } from "../../../lib/grpcapi-admin";
import styles from "./Form.module.css";
import VendorSelect, { ProviderVendor } from "./VendorSelect";

export type FormValues = {
  name?: string;
  vendor?: CommonDomainProviderVendor;
  providerConfig?: any;
};

interface pconfig {
  key: string;
  name: string;
  p: string;
  placeholder?: string;
}

const CONFIGS: pconfig[] = [
  {
    key: "apiKey",
    name: "API Key",
    p: "GANDI",
    placeholder: "Enter API Key",
  },
  {
    key: "sharingID",
    name: "Sharing ID",
    p: "GANDI",
    placeholder: "Enter Sharing ID",
  },
];

interface Props {
  loading?: boolean;
  handleSubmit?: (values: FormValues) => void;
}

const DomainProvidersForm = ({ loading, handleSubmit }: Props) => {
  const [selectedVendor, setSelectedVendor] = useState<ProviderVendor | undefined>();

  // Build initial values
  const initialValues: { [k: string]: any } = {
    name: "",
    vendor: "",
  };
  if (selectedVendor) {
    CONFIGS.filter(c => c.p === selectedVendor.value).forEach(c => {
      initialValues[c.key] = "";
    });
  }

  const handleProviderSelectChange = (vendor: ProviderVendor | undefined) => {
    setSelectedVendor(vendor);
  };

  return (
    <div className={styles.container}>
      <Form
        disabled={loading}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        autoComplete="off"
        onFinish={handleSubmit}
      >
        <Form.Item<FormValues>
          label="Domain provider"
          name="vendor"
          rules={[{ required: true, message: "Backend provider required" }]}
        >
          <VendorSelect onChange={handleProviderSelectChange} />
        </Form.Item>

        <Form.Item<FormValues>
          label="Name"
          name="name"
          rules={[{ required: true, message: "Name required" }]}
        >
          <Input />
        </Form.Item>

        {/* Render provider config fields */}
        {selectedVendor && (
          <Card>
            <b>Provider config for {selectedVendor.label}</b>
            <br />
            <br />

            {CONFIGS.filter(c => c.p === selectedVendor.value).map(providerConfig => (
              <Form.Item<FormValues>
                key={providerConfig.key}
                label={providerConfig.name}
                // name={`providerConfig.${providerConfig.key}`}
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder={providerConfig.placeholder} />
              </Form.Item>
            ))}
          </Card>
        )}

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button loading={loading} type="primary" htmlType="submit">
            Save domain provider
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DomainProvidersForm;
