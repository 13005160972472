import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  padding?: string;
}

const Padder = ({ children, padding }: Props) => {
  if (!padding) {
    padding = "1rem";
  }

  return (
    <div style={{ padding }}>
      {children}
    </div>
  );
};

export default Padder;
