import { EyeOutlined } from "@ant-design/icons";
import { Button, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { TableProps } from "antd/es/table";
import { Link } from "react-router-dom";
import { CommonDomain } from "../../../lib/grpcapi-admin";
import DateRender from "../../utils/DateRender";

interface Props extends TableProps<CommonDomain> {
  domains?: CommonDomain[];
}

const AdminDomainsList = ({ domains, ...otherProps }: Props) => {
  if (!domains) {
    return null;
  }

  const columns: ColumnsType<CommonDomain> = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "ID",
    },
    {
      title: "Status",
      key: "Status",
      render: (domain: CommonDomain) => {
        const r = [
          <span key="status">
            {domain.Status}
          </span>,
        ];

        if (domain.DomainBlockings?.length) {
          r.push(
            <span key="blockings">
              Blockings: {domain.DomainBlockings.map(b => b.CountryCode).join(", ")}
            </span>,
          );
        }

        return (
          <Space>
            {r}
          </Space>
        );
      },
    },
    {
      title: "Public",
      key: "Public",
      render: (domain: CommonDomain) => {
        return domain.Public ? "Yes" : "No";
      },
    },
    {
      title: "Provider",
      key: "CreatedAt",
      render: (domain: CommonDomain) => {
        return domain.DomainProvider?.Name;
      },
    },
    {
      title: "Creation date",
      key: "CreatedAt",
      render: (domain: CommonDomain) => {
        return <DateRender date={domain.CreatedAt} />;
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: 200,
      render: (org) => (
        <Space size="middle">
          <Link to={`/app/admin/domains/${org.ID}`}>
            <Button type="link" icon={<EyeOutlined />}>Show</Button>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <Table
      rowKey="ID"
      bordered
      columns={columns}
      dataSource={domains}
      {...otherProps}
    />
  );
};

export default AdminDomainsList;
