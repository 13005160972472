import { Button, Form, Modal, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../hooks";
import { CommonMirror } from "../../../lib/grpcapi";
import { orgSetMirrorCountryCodes } from "../../../slices/app/org/mirrors";
import CountrySelect, { AllCountries, Country } from "../../utils/CountrySelect";

interface Props {
  mirror?: CommonMirror;
  isOpen?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

type FormValues = {
  countries?: Country[];
};

const OrgSetMirrorCountryCodesDialog = ({ isOpen, onClose, onConfirm, mirror }: Props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const defaultCountries = useRef<Country[]>([]);

  useEffect(() => {
    const newDefaultCountries: Country[] = [];

    if (mirror?.CountryCodes?.length) {
      mirror.CountryCodes.forEach(cc => {
        let country: Country | undefined;

        for (let i = 0; i < AllCountries.length; i++) {
          let c = AllCountries[i];
          if (c.abbreviation === cc) {
            country = c;
            break;
          }
        }

        if (country) {
          newDefaultCountries.push(country);
        }
      });
    }

    const compFn = (a: Country, b: Country): number => {
      if (a.abbreviation > b.abbreviation) {
        return 1;
      }

      if (a.abbreviation < b.abbreviation) {
        return -1;
      }

      return 0;
    };

    newDefaultCountries.sort(compFn);
    defaultCountries.current.sort(compFn);

    if (JSON.stringify(newDefaultCountries) !== JSON.stringify(defaultCountries.current)) {
      defaultCountries.current = newDefaultCountries;
      form.setFieldValue("countries", defaultCountries.current);
    }

    // form.setFieldValue("countries", defaultCountries.current);
  }, [mirror]);

  useEffect(() => {
    form.setFieldValue("countries", defaultCountries.current);
  }, [isOpen]);

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    try {
      await dispatch(orgSetMirrorCountryCodes({
        mirrorID: mirror?.ID as string,
        countryCodes: (values.countries?.map(c => c.abbreviation) || []),
      }));
    } catch (err) {
      console.error(err);
    }

    form.resetFields();
    setLoading(false);
    onConfirm && onConfirm();
  };

  return (
    <Modal
      title="Change mirror country codes"
      open={isOpen}
      footer={null}
      onCancel={() => {
        if (!loading) {
          form.resetFields();
          onClose && onClose();
        }
      }}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        onFinish={handleSubmit}
        form={form}
        autoComplete="off"
      >
        <Form.Item<FormValues>
          label="Countries"
          name="countries"
        >
          <CountrySelect mode="multiple" allowClear />
        </Form.Item>

        <br />

        <Space>
          <Button disabled={loading} onClick={onClose}>Cancel</Button>
          <Button disabled={loading} htmlType="submit" type="primary">
            Save
          </Button>
        </Space>
      </Form>
    </Modal>
  );
};

export default OrgSetMirrorCountryCodesDialog;
