import { useEffect, useState } from "react";
import api from "../../boot/api";
import keycloak, { init as initKeycloak } from "../../boot/keycloak";
import { useAppDispatch } from "../../hooks";
import { setIsAdmin, setProfile } from "../../slices/auth";
import LoadingScreen from "../utils/LoadingScreen";

interface AuthLoaderProps {
  children: JSX.Element;
}

const AuthLoader = ({ children }: AuthLoaderProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      // Check user authentication
      const authenticated = await initKeycloak();

      if (!authenticated) {
        // @TODO: render error
        console.error("Not authenticated");
        return;
      }

      // Make a ping to grpc server
      await api.get("/ping");

      // Get user profile
      const profile = await keycloak.loadUserProfile();
      dispatch(setProfile(profile));

      // Check if user is admin
      dispatch(setIsAdmin(keycloak.hasRealmRole("admin")));

      setLoading(false);
    })();
  }, [dispatch]);

  if (loading) {
    return <LoadingScreen />;
  }

  return children;
};

export default AuthLoader;
