import { Select } from "antd";
import styles from "./VendorSelect.module.css";

export interface ProviderVendor {
  label: string;
  value: string;
}

const PROVIDER_VENDORS: ProviderVendor[] = [
  { label: "Gandi.net", value: "GANDI" },
];

interface Props {
  disabled?: boolean;
  onChange?: (vendor: ProviderVendor | undefined) => void;
}

const DomainProvidersVendorSelect = ({ onChange, disabled }: Props) => {
  const handleChange = (val: any) => {
    let provider: ProviderVendor | undefined;

    PROVIDER_VENDORS.forEach(p => {
      if (p.value === val) {
        provider = p;
      }
    });

    onChange && onChange(provider);
  };

  return (
    <Select
      disabled={disabled}
      className={styles.select}
      options={PROVIDER_VENDORS}
      onChange={handleChange}
      placeholder="Select a provider vendor"
    />
  );
};

export default DomainProvidersVendorSelect;
