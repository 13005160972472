import { Alert, Button, Card, Space } from "antd";
import { message } from "antd";
import axios, { AxiosError } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import adminApi from "../../../../boot/admin-api";
import DomainForm, { FormValues } from "../../../../components/admin/domains/Form";
import Padder from "../../../../components/utils/Padder";
import PageHeader from "../../../../components/utils/PageHeader";
import { AdminCreateDomainsInput } from "../../../../lib/grpcapi-admin";
import styles from "./New.module.css";

const AppDomainsNew = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    setErrorMessage(null);

    // Prepare payload
    const payload: AdminCreateDomainsInput = {
      Names: values.names,
      DomainProviderID: values.provider?.ID,
    };

    // Create domain
    let success = true;
    try {
      await adminApi.post("/domains", payload);
    } catch (err: any | AxiosError) {
      success = false;
      setLoading(false);

      if (axios.isAxiosError(err)) {
        if (err.response?.status === 400) {
          if (err.response.data?.message) {
            setErrorMessage(err.response.data?.message);
            return;
          }
        }
      }

      console.error(err);
      setErrorMessage("Internal error");
    }

    setLoading(false);

    if (success) {
      navigate("/app/admin/domains");
      message.success("Domain created");
      return;
    }
  };

  return (
    <Padder>
      <Card>
        <PageHeader
          title="New domain"
          subTitle="Create a new domain"
          actions={
            <Space>
              <Button onClick={() => navigate(-1)}>
                Go Back
              </Button>
            </Space>
          }
        />
      </Card>

      <div className={styles.content}>
        <div className="row">
          <div className="col-lg-8 col-md-12 col-xs-12">
            <Card>
              {errorMessage && (
                <div style={{ marginBottom: "1rem" }}>
                  <Alert
                    message="Error"
                    description={errorMessage}
                    type="error"
                  />
                </div>
              )}

              <DomainForm
                loading={loading}
                handleSubmit={handleSubmit}
              />
            </Card>
          </div>
        </div>
      </div>
    </Padder>
  );
};

export default AppDomainsNew;
