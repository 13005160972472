import { Tag, Tooltip } from "antd";

interface Props {
  status?: string;
}

const DomainDeploymentStatusLabel = ({ status }: Props) => {
  let color = "default";
  let tooltip = "";

  switch (status) {
    case "WAIT_FOR_DELETION":
      color = "default";
      tooltip = "The domain will be fully deleted on next deployment.";
      break;
    case "CREATED":
      color = "warning";
      tooltip = "The domain will be fully deployed on next deployment.";
      break;
    case "CONFIGURING":
      color = "processing";
      tooltip = "The domain is configuring.";
      break;
    case "CONFIGURED":
      color = "processing";
      tooltip = "The domain is configured.";
      break;
    case "VALIDATING":
      color = "processing";
      tooltip = "The domain is validating.";
      break;
    case "OK":
      color = "success";
      break;
    case "ERROR":
      color = "error";
      break;
  }

  let tag = <Tag color={color}>{status || "NC"}</Tag>;

  if (!tooltip.length) {
    return tag;
  }

  return (
    <Tooltip title={tooltip}>
      {tag}
    </Tooltip>
  );
};

export default DomainDeploymentStatusLabel;
