import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="404"
      title="Nothing here!"
      subTitle="You got lost."
      extra={[
        <Button type="primary" key="home" onClick={() => navigate("/")}>
          Back to home
        </Button>,
      ]}
    />
  );
};

export default NotFound;
