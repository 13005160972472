import { Button, Form, Modal, Slider, Space } from "antd";
import { useState } from "react";
import api from "../../../boot/api";
import { CommonMirror } from "../../../lib/grpcapi";

interface Props {
  mirror?: CommonMirror;
  isOpen?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

type FormValues = {
  count?: Number;
};

const OrgGenDomainsForMirrorDialog = ({ isOpen, onClose, onConfirm, mirror }: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);

    const payload = {
      MirrorID: mirror?.ID,
      Count: values.count,
    };

    try {
      await api.post(`/org/mirrors/${mirror?.ID}/gen-domains`, payload);
    } catch (err) {
      console.error(err);
    }

    form.resetFields();
    setLoading(false);
    onConfirm && onConfirm();
  };

  return (
    <Modal
      title="Generate domains for mirror"
      open={isOpen}
      footer={null}
      onCancel={() => {
        if (!loading) {
          form.resetFields();
          onClose && onClose();
        }
      }}
    >
      <div>
        <b>Mirror</b>: <span>{mirror?.Name}</span>
      </div>

      <br />

      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ count: 1 }}
        onFinish={handleSubmit}
        form={form}
        autoComplete="off"
      >
        <Form.Item
          label="Count"
          name="count"
          rules={[{ required: true, message: "Please select a count!" }]}
        >
          <Slider disabled={loading} min={1} max={5} />
        </Form.Item>

        <br />

        <Space>
          <Button disabled={loading} onClick={onClose}>Cancel</Button>
          <Button disabled={loading} htmlType="submit" type="primary">
            Generate domains
          </Button>
        </Space>
      </Form>
    </Modal>
  );
};

export default OrgGenDomainsForMirrorDialog;
