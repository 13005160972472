import { Button, Form, Input } from "antd";
import styles from "./Form.module.css";

interface Props {
  loading?: boolean;
  handleSubmit?: (values: { [k: string]: any }) => void;
}

const OrganizationForm = ({ loading, handleSubmit }: Props) => {
  return (
    <div className={styles.container}>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={handleSubmit}
        style={{ maxWidth: 600 }}
        autoComplete="off"
      >
        <Form.Item
          label="Orgnization name"
          name="name"
          rules={[{ required: true, message: "Enter the organization name" }]}
        >
          <Input disabled={loading} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button disabled={loading} loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default OrganizationForm;
