import { Button, Form, Input, Switch } from "antd";
import { CommonBackendProvider, CommonCDNProvider } from "../../../lib/grpcapi";
import CountrySelect, { Country } from "../../utils/CountrySelect";
import OrgBackendProviderSelect from "../backend-providers/Select";
import OrgCDNProviderSelect from "../cdn-providers/Select";

interface Props {
  handleSubmit?: (values: FormValues) => void;
  loading?: boolean;
}

export type FormValues = {
  name?: string;
  backendProvider?: CommonBackendProvider;
  cdnProvider?: CommonCDNProvider;
  country_codes?: Country[];
  private?: boolean;
};

const OrgMirrorForm = ({ handleSubmit, loading }: Props) => {
  return (
    <Form
      disabled={loading}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{ private: true }}
      autoComplete="off"
      onFinish={handleSubmit}
    >
      <Form.Item<FormValues>
        label="Name"
        name="name"
        rules={[{ required: true, message: "Name required" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item<FormValues>
        label="Backend Provider"
        name="backendProvider"
        rules={[{ required: true, message: "Backend provider required" }]}
      >
        <OrgBackendProviderSelect />
      </Form.Item>

      <Form.Item<FormValues>
        label="CDN Provider"
        name="cdnProvider"
        rules={[{ required: true, message: "CDN provider required" }]}
      >
        <OrgCDNProviderSelect />
      </Form.Item>

      <Form.Item<FormValues>
        label="Countries"
        name="country_codes"
        rules={[{ required: true, message: "Country required" }]}
      >
        <CountrySelect mode="multiple" allowClear />
      </Form.Item>

      <Form.Item label="Private ?" name="private" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button loading={loading} type="primary" htmlType="submit">
          Create mirror
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OrgMirrorForm;
