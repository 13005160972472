import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Descriptions, Empty, Row, Space } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddUserToOrgDialog from "../../../../components/admin/organizations/AddUserToOrgDialog";
import AdminOrganizationsRolesList from "../../../../components/admin/organizations/roles/List";
import DateRender from "../../../../components/utils/DateRender";
import LoadingView from "../../../../components/utils/LoadingView";
import Padder from "../../../../components/utils/Padder";
import PageHeader from "../../../../components/utils/PageHeader";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { adminFetchOrganization, adminFetchOrganizationUsers } from "../../../../slices/app/admin/organizations";
import { setBreadcrumpItems } from "../../../../slices/breadcrump";
import styles from "./Show.module.css";

const AddAdminOrganizationShow = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const organization = useAppSelector(state => state.app.admin.organizations.organization);
  const organizationUsers = useAppSelector(state => state.app.admin.organizations.organzationUsers);

  const [loading, setLoading] = useState(true);
  const [addUserToOrgDialogOpen, setAddUserToOrgDialogOpen] = useState(false);

  useEffect(() => {
    const breadcrumpItems = [
      {
        label: "Admin",
        link: "/app/admin",
      },
      {
        label: "Organizations",
        link: "/app/admin/organizations",
      },
    ];

    if (organization?.ID === params.id) {
      breadcrumpItems.push({
        label: organization?.Name || "",
        link: `/app/admin/organizations/${params.id}`,
      });
    }

    dispatch(setBreadcrumpItems(breadcrumpItems));
  }, [dispatch, organization, params.id]);

  const fetchOrganization = useCallback(async () => {
    await dispatch(adminFetchOrganization(params.id as string));
  }, [dispatch, params.id]);

  const fetchOrganizationUsers = useCallback(async () => {
    await dispatch(adminFetchOrganizationUsers(params.id as string));
  }, [dispatch, params.id]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await Promise.all([fetchOrganization(), fetchOrganizationUsers()]);
      setLoading(false);
    })();
  }, [params.id, dispatch, fetchOrganization, fetchOrganizationUsers]);

  if (loading) {
    return <LoadingView />;
  }

  if (!organization) {
    return (
      <Padder>
        <Empty description="Error while loading organization" />
      </Padder>
    );
  }

  return (
    <Padder>
      <Card>
        <PageHeader
          title={organization?.Name || ""}
          subTitle="Organization"
          actions={
            <Space>
              <Button onClick={() => navigate(-1)}>
                Go Back
              </Button>
            </Space>
          }
        />
      </Card>
      <div className={styles.content}>
        <Row gutter={16}>
          <Col span={12}>
            <Card title="Informations">
              <Descriptions>
                <Descriptions.Item label="Name">{organization.Name}</Descriptions.Item>
                <Descriptions.Item label="Creation date">
                  <DateRender date={organization.CreatedAt} />
                </Descriptions.Item>
                <Descriptions.Item label="Last update date">
                  <DateRender date={organization.UpdatedAt} />
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              bodyStyle={{ padding: 0 }}
              title="Users"
              extra={
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  onClick={() => setAddUserToOrgDialogOpen(true)}
                >
                  Add user
                </Button>
              }
            >
              <AdminOrganizationsRolesList
                loading={loading}
                roles={organizationUsers}
              />
            </Card>
          </Col>
        </Row>
      </div>

      <AddUserToOrgDialog
        organization={organization}
        isOpen={addUserToOrgDialogOpen}
        onClose={() => {
          setAddUserToOrgDialogOpen(false);
        }}
        onConfirm={() => {
          setAddUserToOrgDialogOpen(false);
          fetchOrganizationUsers();
        }}
      />
    </Padder>
  );
};

export default AddAdminOrganizationShow;
